<template>
	<div class="PopOrderMarker">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="shenqingshouhou">
				<div class="pop-header">
					<div class="ph-title">订单标记</div>
					<div class="ph-close" @click="close()"></div>
				</div>
				<el-form ref="ruleForm" class="pop-body">
					<div class="cb-row">
						<div class="cbr-label">标记颜色：</div>
						<div class="cbr-content">
							<el-radio-group v-model="markerType">
								<el-radio label="red">
									<svg-icon icon-class="flag" style="fill: red;" />
								</el-radio>
								<el-radio label="gray">
									<svg-icon icon-class="flag" style="fill: gray;" />
								</el-radio>
								<el-radio label="yellow">
									<svg-icon icon-class="flag" style="fill: yellow;" />
								</el-radio>
								<el-radio label="green">
									<svg-icon icon-class="flag" style="fill: green;" />
								</el-radio>
								<el-radio label="blue">
									<svg-icon icon-class="flag" style="fill: blue;" />
								</el-radio>
								<el-radio label="purple">
									<svg-icon icon-class="flag" style="fill: purple;" />
								</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label" style="align-self: flex-start;">标记内容：</div>
						<div class="cbr-content">
							<el-form-item prop="description" class="form-item">
								<el-input v-model="markerValue" class="textarea type-1" type="textarea"
									:show-word-limit="true" :maxlength="400" :rows="2" placeholder="请输入"
									clearable></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close()">取消</div>
					<div class="pf-confirm btn-fill" @click="confirm()">确定</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'PopOrderMarker', //申请售后
		props: {
			show: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				dialogVisible: false,
				markerType: '',
				markerValue: '',
				orderId: '',

			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					if (!val) {
						this.$refs.ruleForm.resetFields()
					}
					this.$emit('update:show', val)
				}
			},

		},
		methods: {
			open(data = {}) {
				this.markerType = data.markerType || 'gray'
				this.markerValue = data.markerValue || ''
				this.orderId = data.orderId || ''
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			confirm() {
				let data = {
					markerType: this.markerType,
					markerValue: this.markerValue,
					orderId: this.orderId
				}
				this.$emit('confirm', data)
				this.close()
			},

		}
	}
</script>

<style lang="scss" scoped>
	.PopOrderMarker {
		.shenqingshouhou {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;

			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;

				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}

				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}

			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;

				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;

					.cbr-label {
						width: 100px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;

						.star {
							color: #FF0000;
						}
					}

					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;

						.el-radio-group {
							display: flex;
							align-items: center;
						}

						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 320px;
							}
						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							.el-input__suffix {
								display: flex;
								align-items: center;
							}

							&.type-1 {
								width: 320px;
							}

						}

						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 420px;
							}
						}

						.upload-demo {
							background: transparent;
							width: 100px;
							height: 100px;
							overflow: hidden;
							border-radius: 4px;
							// box-shadow: 0px 2px 8px 0px rgba(203, 203, 203, 0.5);
							margin-right: 10px;

							&:hover {

								// box-shadow: 0px 2px 8px 0px #3571DA;
								.upload-item {
									border-color: #3571DA;
								}
							}

							::v-deep {
								.el-upload-list--picture-card .el-upload-list__item {
									width: 100px;
									height: 100px;
								}
							}
						}

						.upload-item {
							width: 100px;
							height: 100px;
							background: #F6F8FC;
							border-radius: 4px;
							border: 1px dashed #CED4DB;
							box-sizing: border-box;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							margin-right: 10px;

							.ui-icon {
								width: 24px;
								height: 24px;
								background-image: url('../assets/052.png');
								background-size: 100% 100%;
								margin-bottom: 8px;
							}

							.ui-text {
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #8D9094;
								line-height: 12px;
							}

						}

					}
				}

			}

			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;

				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}

				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>