import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import ElementUI from './element-ui';
// import './element-ui/lib/theme-chalk/index.css';
import './common/flexible.js'
import './common/directive.js'
import 'animate.css';

import router from './router'
import store from './store'
import mixins from './mixins'
import modal from './common/modal.js'
import FileUpload from './components/FileUpload.vue'
import NoData from './components/NoData.vue'
import PopTip from './components/PopTip.vue'
import PopRichText from './components/PopRichText.vue'
import Countdown from './components/Countdown.vue'
import Media from './components/Media.vue'
import {
	XKFInit
} from './common/xiaokefu.js'
const moment = require('moment');

Vue.component('FileUpload', FileUpload)

Vue.component('NoData', NoData)

Vue.component('PopTip', PopTip)

Vue.component('PopRichText', PopRichText)

Vue.component('Countdown', Countdown)

Vue.component('Media', Media)

Vue.prototype.$moment = moment

Vue.use(ElementUI);

Vue.mixin(mixins)

Vue.prototype.$modal = modal

// svg
import '@/common/svg.js'

Vue.config.productionTip = false

const app = new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	},
	mounted() {
		(XKFInit)(window, document, 'script', '_xiaokefu');

	}
}).$mount('#app')

// 将app实例挂载到Vue的原型，这样你可以在任何组件中通过this.$root来访问它
Vue.prototype.$rootApp = app.$children[0];