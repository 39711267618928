<template>
	<div class="PopAfterSalesApply">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="shenqingshouhou">
				<div class="pop-header">
					<div class="ph-title">申请售后</div>
					<div class="ph-close" @click="close()"></div>
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="pop-body">
					<div class="cb-row">
						<div class="cbr-label">售后类型：</div>
						<div class="cbr-content">
							<el-radio-group v-model="ruleForm.type">
								<el-radio :label="1">申请赔偿</el-radio>
								<el-radio :label="2">申请售后</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="cb-row" v-if="ruleForm.type == 1">
						<div class="cbr-label">赔偿金额：</div>
						<div class="cbr-content">
							<el-form-item prop="money" class="form-item yanzhengma">
								<el-input v-model="ruleForm.money" class="input type-1" placeholder="请输入" clearable
									@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'money',})">
									<template slot="prepend">￥</template>
								</el-input>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">申请理由：</div>
						<div class="cbr-content">
							<el-form-item prop="" class="form-item">
								<el-select class="select type-1" v-model="ruleForm.reasons" placeholder="请选择">
									<el-option v-for="item in reasonsOptions[ruleForm.type - 1]" :key="item.content"
										:label="item.content" :value="item.content">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label" style="align-self: flex-start;">申请说明：</div>
						<div class="cbr-content">
							<el-form-item prop="description" class="form-item">
								<el-input v-model="ruleForm.description" class="textarea type-1" type="textarea"
									:show-word-limit="true" :maxlength="400" :rows="2" placeholder="请输入"
									clearable></el-input>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label" style="align-self: flex-start;">凭证上传：</div>
						<div class="cbr-content">
							<el-form-item prop="" class="form-item">
								<FileUpload class="upload-demo" className="w100h100" v-model="ruleForm.voucher"
									:limit="1" dir="voucher">
									<div class="upload-item">
										<div class="ui-icon"
											:style="{backgroundImage: `url('${require('../assets/051.png')}')`}"></div>
										<div class="ui-text">上传产品图片</div>
									</div>
								</FileUpload>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close()">取消</div>
					<div class="pf-confirm btn-fill" @click="submit()">确定</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		AfterSalesApply,
		AfterSalesReason
	} from '../common/api.js'
	var that = {}
	var ruleForm = {
		description: '', // 说明
		orderId: '', // 工单Id
		type: 1, // 类型1赔偿2维修
		voucher: '', // 凭证图片
		money: '', // 赔偿金额
		reasons: '', // 理由
	}

	export default {
		name: 'PopAfterSalesApply', //申请售后
		props: {
			show: {
				type: Boolean,
				default: false
			},
		},
		data() {
			var moneyChecked = (rule, value, callback) => {
				if (this.ruleForm.type != 1 || value) {
					callback();
				} else {
					return callback(new Error('请输入赔偿金额'));
				}
			};
			return {
				dialogVisible: false,
				ruleForm: {
					...ruleForm
				},
				rules: {
					money: [{
						required: true,
						trigger: 'blur',
						validator: moneyChecked
					}],
					description: [{
						required: true,
						message: '请输入申请说明',
						trigger: 'blur'
					}],
				},
				reasonsOptions: [],

			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					if (!val) {
						this.$refs.ruleForm.resetFields()
					}
					this.$emit('update:show', val)
				}
			},

		},
		created() {
			that = this
			this.getAfterSalesReason()
		},
		methods: {
			open(data) {
				if (!data) {
					this.ruleForm = {
						...ruleForm
					}
				} else {
					this.ruleForm.orderId = data.id // id
				}
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			getAfterSalesReason() {
				AfterSalesReason(1).then(res => {
					this.reasonsOptions.push(res.data)
					AfterSalesReason(2).then(res => {
						this.reasonsOptions.push(res.data)
					})
				})
			},
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = {
							description: this.ruleForm.description, // 说明
							orderId: this.ruleForm.orderId, // 工单Id
							type: this.ruleForm.type, // 类型1赔偿2维修
							voucher: this.ruleForm.voucher, // 凭证图片
							money: this.ruleForm.type == 1 ? this.ruleForm.money : '', // 赔偿金额
							reasons: this.ruleForm.reasons, // 理由
						}
						AfterSalesApply(data).then(res => {
							this.toast({
								message: '添加成功',
								type: 'success'
							})
							this.close()
							this.$emit('applySuccess')
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

		}
	}
</script>

<style lang="scss" scoped>
	.PopAfterSalesApply {
		.shenqingshouhou {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;

			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;

				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}

				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}

			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;

				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;

					.cbr-label {
						width: 100px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;

						.star {
							color: #FF0000;
						}
					}

					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;

						.el-radio-group {
							display: flex;
							align-items: center;
						}

						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 320px;
							}
						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							.el-input__suffix {
								display: flex;
								align-items: center;
							}

							&.type-1 {
								width: 320px;
							}

						}

						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 420px;
							}
						}

						.upload-demo {
							background: transparent;
							width: 100px;
							height: 100px;
							overflow: hidden;
							border-radius: 4px;
							// box-shadow: 0px 2px 8px 0px rgba(203, 203, 203, 0.5);
							margin-right: 10px;

							&:hover {

								// box-shadow: 0px 2px 8px 0px #3571DA;
								.upload-item {
									border-color: #3571DA;
								}
							}

							::v-deep {
								.el-upload-list--picture-card .el-upload-list__item {
									width: 100px;
									height: 100px;
								}
							}
						}

						.upload-item {
							width: 100px;
							height: 100px;
							background: #F6F8FC;
							border-radius: 4px;
							border: 1px dashed #CED4DB;
							box-sizing: border-box;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							margin-right: 10px;

							.ui-icon {
								width: 24px;
								height: 24px;
								background-image: url('../assets/052.png');
								background-size: 100% 100%;
								margin-bottom: 8px;
							}

							.ui-text {
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #8D9094;
								line-height: 12px;
							}

						}

					}
				}

			}

			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;

				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}

				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>