<template>
	<svg :class="svgClass" aria-hidden="true" v-on="$listeners">
		<use :xlink:href="iconName" />
	</svg>
</template>

<script>
export default {
	name: 'SvgIcon',
	props: {
		iconClass: {
			type: String,
			required: true
		},
		className: {
			type: String,
			default: ''
		}
	},
	computed: {
		iconName() {
			return `#icon-${this.iconClass}`;
		},
		svgClass() {
			if (this.className) {
				return `svg-icon svg-${this.iconClass} ${this.className}`;
			} else {
				return `svg-icon svg-${this.iconClass}`;
			}
		}
	}
};
</script>

<style scoped>
.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
/* .svg-arrow-down {
	fill: #b5b5b5;
}
.svg-hj {
	fill: #666666;
}
.svg-jia {
	fill: #1d67e8;
	stroke: #fff;
}
.svg-shanc {
	stroke: #000000;
}
.svg-sz {
	stroke: #5a5a68;
}

.svg-sz-3 {
	fill: #666873;
} */
</style>
