<template>
	<div class="PopProductTechnical">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="xiugaidenglumima">
				<div class="pop-header">
					<div class="ph-title">修改</div>
					<div class="ph-close pointer" @click="close"></div>
				</div>
				<el-form class="pop-body">
					<div class="cb-row">
						<div class="cbr-label">技术指导员：</div>
						<div class="cbr-content">
							<el-form-item prop="name" class="form-item">
								<el-input v-model="details.name" class="input type-2" placeholder="请输入姓名" clearable
									></el-input>
							</el-form-item>
							<el-form-item prop="phone" class="form-item" style="margin-left: 20px;">
								<el-input v-model="details.phone" class="input type-2" placeholder="请输入手机号" clearable></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close">取消</div>
					<div class="pf-confirm btn-fill" @click="submit">确定</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ProductsTechnicalUpdate
	} from '@/common/api.js'
	export default {
		name: 'PopProductTechnical', //
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				dialogVisible: false,
				details: {}
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					this.$emit('update:show', val)
				}
			},

		},
		methods: {
			open(data) {
				this.details = JSON.parse(JSON.stringify(data))
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			submit() {
				if (this.details.phone && !this.verifyPhone({val: this.details.phone})) {
					return
				}
				let data = {
					id: this.details.id,
					name: '',
					phone: '',
				}
				if (this.details.name && this.details.phone) {
					data.name = this.details.name
					data.phone = this.details.phone
				}
				ProductsTechnicalUpdate(data).then(res => {
					this.toast({
						message: '修改成功',
						type: 'success'
					})
					this.close()
					this.$emit('success')
				})
			},

		}
	}
</script>

<style lang="scss" scoped>
	.PopProductTechnical {
		.xiugaidenglumima {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;

			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;

				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}

				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}

			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;

				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;

					.cbr-label {
						width: 120px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;

						.star {
							color: #FF0000;
						}
					}

					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;

						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						.yanzhengma {
							position: relative;

							.yanzhengma-btn {
								position: absolute;
								right: 0;
								top: 0;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #3571DA;
								line-height: 32px;
								width: 80px;
								text-align: center;
							}
						}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 320px;
							}
						}
					}
				}

			}

			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;

				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}

				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>