<template>
	<div class="login">
		<div class="login-left">
			<div class="bg"></div>
		</div>
		<div class="login-right" key="type-1-loginType-1" v-if="type == 1 && loginType == 1">
			<el-form :model="accountRuleForm" :rules="accountRules" ref="accountRuleForm" class="login-form">
				<div class="lf-heading">厂商登录</div>
				<div class="lf-tab">
					<div class="lft-item pointer" :class="{active: index == isSubAccountFlag}" v-for="(item,index) in accountTab" :key="index" @click="changeIsSubAccountFlag(index)">
						<div class="lft-text " >{{item}}</div>
						<div class="lft-vertical-line" v-if="index == 0"></div>
						<div class="lft-under-line"></div>
					</div>
				</div>
				<template v-if="isSubAccountFlag == 0">
					<el-form-item prop="phone" class="box-1" key="phone">
						<div class="lf-input-box phone box-1">
							<div class="lib-icon phone"></div>
							<el-input v-model="accountRuleForm.phone" type="text" class="lib-input" clearable
								autocomplete="new-password" :maxlength="11" placeholder="请输入手机号码">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item prop="phonePassword" class="box-2">
						<div class="lf-input-box password box-2">
							<div class="lib-icon lock"></div>
							<el-input v-model="accountRuleForm.phonePassword" class="lib-input" clearable show-password
								autocomplete="new-password" placeholder="请输入密码">
							</el-input>
						</div>
					</el-form-item>
				</template>
				<template v-if="isSubAccountFlag == 1">
					<el-form-item prop="account" class="box-1" key="phone">
						<div class="lf-input-box phone box-1">
							<div class="lib-icon phone"></div>
							<el-input v-model="accountRuleForm.account" type="text" class="lib-input" clearable
								autocomplete="new-password" placeholder="请输入账号">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item prop="accountPassword" class="box-2">
						<div class="lf-input-box password box-2">
							<div class="lib-icon lock"></div>
							<el-input v-model="accountRuleForm.accountPassword" class="lib-input" clearable show-password
								autocomplete="new-password" placeholder="请输入密码">
							</el-input>
						</div>
					</el-form-item>
				</template>
				
				<div class="lf-tools">
					<div class="lft-remember-password">
						<el-form-item prop="">
							<div class="checked-Box">
								<el-checkbox-group v-model="accountRuleForm.checked">
									<el-checkbox name="checked">
										<div class="cb-label">
											记住密码
										</div>
									</el-checkbox>
								</el-checkbox-group>
							</div>
						</el-form-item>
					</div>
					<div class="lft-right">
						<div class="lftr-forget-password pointer" @click="changeType(3)">忘记密码</div>
						<div class="lftr-line"></div>
						<div class="lftr-verification-code-login pointer" @click="changeLoginType(2)">验证码登录</div>
					</div>
				</div>
				<div class="lf-login-btn btn-fill" @click="login">立即登录</div>
				<div class="lf-toRegister">
					没有账号？
					<span class="lft-highlight pointer" @click="changeType(2)">立即注册>></span>
				</div>
			</el-form>
		</div>
		<div class="login-right" key="type-1-loginType-2" v-if="type == 1 && loginType == 2">
			<el-form :model="yanzhengmaLoginRuleForm" :rules="yanzhengmaLoginRules" ref="yanzhengmaLoginRuleForm" class="login-form">
				<div class="lf-heading">验证码登录</div>
				<div class="lf-tab">
					<div class="lft-item pointer" :class="{active: index == isSubAccountFlag}" v-for="(item,index) in accountTab" :key="index" @click="changeIsSubAccountFlag(index)">
						<div class="lft-text " >{{item}}</div>
						<div class="lft-vertical-line" v-if="index == 0"></div>
						<div class="lft-under-line"></div>
					</div>
				</div>
				<el-form-item prop="phone" class="box-3">
					<div class="lf-input-box phone box-3">
						<div class="lib-icon phone"></div>
						<el-input type="number" class="lib-input" clearable v-model="yanzhengmaLoginRuleForm.phone"
							autocomplete="new-password" :maxlength="11" placeholder="请输入手机号码">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item prop="code" class="box-4 yanzhengma">
					<div class="lf-input-box yanzhengma box-4 ">
						<div class="lib-icon safe"></div>
						<el-input type="text" class="lib-input" clearable v-model="yanzhengmaLoginRuleForm.code"
							autocomplete="new-password" :maxlength="11" placeholder="请输入图形验证码">
						</el-input>
						<div class="verify-image" @click="getCaptchaImage()">
							<img :src="captchaImage" alt="" />
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="captcha" class="box-4 yanzhengma">
					<div class="lf-input-box yanzhengma box-4 ">
						<div class="lib-icon safe"></div>
						<el-input type="text" class="lib-input" clearable v-model="yanzhengmaLoginRuleForm.captcha"
							autocomplete="new-password" :maxlength="11" placeholder="请输入短信验证码">
						</el-input>
						<Countdown  ref="yanzhengmaLoginCountdown" :seconds="60" class="yanzhengma-btn" @click="getCode"></Countdown>
					</div>
				</el-form-item>
				<div class="lf-tools">
					<div class="lft-remember-password">
						<!-- <div class="checked-Box" @click="agree=!agree">
							<div class="cb-icon">
								<div class="cbi-no" v-if="!agree"></div>
								<div class="cbi-yes" v-else></div>
							</div>
							<div class="cb-label">记住密码</div>
						</div> -->
					</div>
					<div class="lft-right">
						<div class="lftr-forget-password pointer" @click="changeType(3)">忘记密码</div>
						<div class="lftr-line"></div>
						<div class="lftr-verification-code-login pointer" @click="changeLoginType(1)">密码登录</div>
					</div>
				</div>
				<div class="lf-login-btn btn-fill" @click="login">立即登录</div>
				<div class="lf-toRegister">
					没有账号？
					<span class="lft-highlight pointer" @click="changeType(2)">立即注册>></span>
				</div>
			</el-form>
		</div>
		<div class="login-right" key="type-2-process-1" v-if="type == 2 && process == 1">
			<el-form :model="vCodeRuleForm" :rules="vCodeRules" ref="vCodeRuleForm" class="login-form">
				<div class="lf-heading">欢迎注册</div>
				<div class="lf-process">
					<div class="lfp-item active">
						<div class="lfpi-num">1</div>
						<div class="lfpi-text">验证手机号</div>
					</div>
					<div class="lfp-line"></div>
					<div class="lfp-item">
						<div class="lfpi-num">2</div>
						<div class="lfpi-text">完善信息</div>
					</div>
					<div class="lfp-line"></div>
					<div class="lfp-item">
						<div class="lfpi-num">3</div>
						<div class="lfpi-text">完成注册</div>
					</div>
				</div>
				<el-form-item prop="phone" class="box-9">
					<div class="lf-input-box phone box-9">
						<div class="lib-icon phone"></div>
						<el-input v-model="vCodeRuleForm.phone" type="text" class="lib-input" clearable :maxlength="11"
							autocomplete="new-password" placeholder="请输入手机号码">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item prop="code" class="box-10">
					<div class="lf-input-box yanzhengma box-10">
						<div class="lib-icon safe"></div>
						<el-input v-model="vCodeRuleForm.code" type="text" class="lib-input" clearable :maxlength="6"
							autocomplete="new-password" placeholder="请输入图形验证码">
						</el-input>
						<div class="verify-image" @click="getCaptchaImage()">
							<img :src="captchaImage" alt="" />
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="captcha" class="box-10">
					<div class="lf-input-box yanzhengma box-10">
						<div class="lib-icon safe"></div>
						<el-input v-model="vCodeRuleForm.captcha" type="text" class="lib-input" clearable :maxlength="6"
							autocomplete="new-password" placeholder="请输入短信验证码">
						</el-input>
						<Countdown  ref="vCodeCountdown" :seconds="60" class="yanzhengma-btn" @click="getCode"></Countdown>
					</div>
				</el-form-item>
				<div class="lf-tools">
					<div class="lft-protocol">
						<el-form-item prop="checked">
							<div class="checked-Box">
								<el-checkbox-group v-model="vCodeRuleForm.checked">
									<el-checkbox name="checked">
										<div class="cb-label">
											我已阅读并同意
										</div>
									</el-checkbox>
								</el-checkbox-group>
								<div class="cb-label">
									<span class="highlight pointer" @click="openPopRichText(2)">《售后协议》</span>
									<span class="highlight pointer" @click="openPopRichText(1)">《隐私协议》</span>
								</div>
							</div>
						</el-form-item>
					</div>
				</div>
				<div class="lf-login-btn pointer btn-fill" @click="changeProcess(2)">注册</div>
				<div class="lf-toRegister">
					已有账号？
					<span class="lft-highlight pointer" @click="changeType(1)">直接登录>></span>
				</div>
			</el-form>
		</div>
		<div class="login-right" key="type-2-process-2" v-if="type == 2 && process == 2">
			<el-form :model="perfectRuleForm" :rules="perfectRules" ref="perfectRuleForm" class="login-form">
				<div class="lf-heading">欢迎注册</div>
				<div class="lf-process">
					<div class="lfp-item ok">
						<div class="lfpi-num"></div>
						<div class="lfpi-text">验证手机号</div>
					</div>
					<div class="lfp-line ok"></div>
					<div class="lfp-item active">
						<div class="lfpi-num">2</div>
						<div class="lfpi-text">完善信息</div>
					</div>
					<div class="lfp-line"></div>
					<div class="lfp-item">
						<div class="lfpi-num">3</div>
						<div class="lfpi-text">完成注册</div>
					</div>
				</div>
				<el-form-item prop="" class="box-11">
					<div class="lf-input-box select box-11">
						<div class="lib-icon people"></div>
						<el-input value="厂商" disabled class="lib-input" placeholder="请选择">
						</el-input>
						<div class="lib-arrow"></div>
					</div>
				</el-form-item>
				<el-form-item prop="company" class="box-12">
					<div class="lf-input-box box-12">
						<div class="lib-icon edit"></div>
						<el-input v-model="perfectRuleForm.company" class="lib-input" clearable
							autocomplete="new-password" placeholder="请输入您的公司/店铺名称">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item prop="password" class="box-13">
					<div class="lf-input-box password box-13">
						<div class="lib-icon lock"></div>
						<el-input v-model="perfectRuleForm.password" class="lib-input" clearable show-password
							autocomplete="new-password" placeholder="请设置登录密码">
						</el-input>
						<div class="lib-input-tip">密码长度必须为6-20位，建议包含大小写字母及数字</div>
					</div>
				</el-form-item>
				<div class="lf-login-btn pointer btn-fill" @click="changeProcess(3)">提交</div>
			</el-form>
		</div>
		<div class="login-right" key="type-2-process-3" v-if="type == 2 && process == 3">
			<div class="login-form">
				<div class="lf-heading">欢迎注册</div>
				<div class="lf-process">
					<div class="lfp-item ok">
						<div class="lfpi-num"></div>
						<div class="lfpi-text">验证手机号</div>
					</div>
					<div class="lfp-line ok"></div>
					<div class="lfp-item ok">
						<div class="lfpi-num"></div>
						<div class="lfpi-text">完善信息</div>
					</div>
					<div class="lfp-line ok"></div>
					<div class="lfp-item active">
						<div class="lfpi-num">3</div>
						<div class="lfpi-text">完成注册</div>
					</div>
				</div>
				<div class="lf-success-icon"></div>
				<div class="lf-success-text">注册完成</div>
				<div class="lf-xiadan-btn pointer btn-fill" @click="toIndex">立即下单找师傅</div>
			</div>
		</div>
		<div class="login-right" key="type-3" v-if="type == 3">
			<el-form :model="forgetRuleForm" :rules="forgetRules" ref="forgetRuleForm" class="login-form">
				<div class="lf-heading">忘记密码</div>
				<el-form-item prop="phone" class="box-5">
					<div class="lf-input-box phone box-5">
						<div class="lib-icon phone"></div>
						<el-input type="text" class="lib-input" clearable v-model="forgetRuleForm.phone"
							autocomplete="new-password" :maxlength="11" placeholder="请输入手机号码">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item prop="code" class="box-6">
					<div class="lf-input-box yanzhengma box-6">
						<div class="lib-icon safe"></div>
						<el-input type="text" class="lib-input" clearable v-model="forgetRuleForm.code"
							autocomplete="new-password" :maxlength="11" placeholder="请输入图形验证码">
						</el-input>
						<div class="verify-image" @click="getCaptchaImage()">
							<img :src="captchaImage" alt="" />
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="captcha" class="box-6">
					<div class="lf-input-box yanzhengma box-6">
						<div class="lib-icon safe"></div>
						<el-input type="text" class="lib-input" clearable v-model="forgetRuleForm.captcha"
							autocomplete="new-password" :maxlength="11" placeholder="请输入短信验证码">
						</el-input>
						<Countdown  ref="forgetCountdown" :seconds="60" class="yanzhengma-btn" @click="getCode"></Countdown>
					</div>
				</el-form-item>
				<el-form-item prop="password" class="box-7">
					<div class="lf-input-box password box-7">
						<div class="lib-icon lock"></div>
						<el-input type="text" class="lib-input" clearable v-model="forgetRuleForm.password" show-password
							autocomplete="new-password" :maxlength="11" placeholder="请设置新密码">
						</el-input>
						<div class="lib-input-tip">密码长度必须为6-20位，建议包含大小写字母及数字</div>
					</div>
				</el-form-item>
				<el-form-item prop="passwordConfirm" class="box-8">
					<div class="lf-input-box password box-8">
						<div class="lib-icon lock"></div>
						<el-input type="text" class="lib-input" clearable v-model="forgetRuleForm.passwordConfirm" show-password
							autocomplete="new-password" :maxlength="11" placeholder="确认密码">
						</el-input>
					</div>
				</el-form-item>
				<div class="lf-login-btn btn-fill" @click="updateForgetConfirm">确定</div>
				<div class="lf-toRegister">
					已有账号？
					<span class="lft-highlight pointer" @click="changeType(1)">直接登录>></span>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		SendCode,
		Register,
		Login,
		LoginMobile,
		UpdateForget,
		ConfigAgreement,
		CaptchaImage
	} from '../common/api.js'
	export default {
		props: ['typeNum'],
		data() {
			var checkChecked = (rule, value, callback) => {
				// console.log('value: ', value);
				if (value) {
					callback();
				} else {
					return callback(new Error('请勾选协议'));
				}
			};

			var numberChecked = (rule, value, callback) => {
				// console.log('value: ', value);
				if (value) {
					if (!isNaN(parseFloat(value)) && isFinite(value)) {
						callback();
					} else {
						return callback(new Error('请勿输入数字以外的字符'));
					}
				} else {
					callback();
				}
				
			};
			
			var passwordConfirmChecked = (rule, value, callback) => {
				if (value) {
					if (this.forgetRuleForm.password === this.forgetRuleForm.passwordConfirm) {
						callback();
					} else {
						return callback(new Error('2次输入的密码不一致'));
					}
				} else {
					return callback(new Error('请输入确认密码'));
				}
				
			};
			
			var passwordChecked = (rule, value, callback) => {
				if (value) {
					if (this.forgetRuleForm.passwordConfirm) {
						this.$refs.forgetRuleForm.validateField(['passwordConfirm'], (valid) => {
							
						});
					}
					callback();
				} else {
					return callback(new Error('请输入密码'));
				}
				
			};

			return {
				agree: false,
				type: 1, // 1 登录 2 注册 3 忘记密码
				loginType: 1, // 1 密码登录 2 验证码登录
				process: 1,

				accountRuleForm: {
					phone: '',
					account: '',
					phonePassword: '',
					accountPassword: '',
					checked: false
				},
				accountRules: {
					phone: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 11,
						max: 11,
						message: '手机号位数不正确',
						trigger: 'blur'
					}],
					account: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					phonePassword: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					accountPassword: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
				},
				vCodeRuleForm: {
					phone: '',
					captcha: '',
					checked: false,
					code: ''
				},
				vCodeRules: {
					phone: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 11,
						max: 11,
						message: '手机号位数不正确',
						trigger: 'blur'
					}],
					captcha: [{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 6,
						max: 6,
						message: '短信验证码位数不正确',
						trigger: 'blur'
					}],
					checked: [{
						type: 'boolean',
						required: true,
						trigger: 'change',
						validator: checkChecked
					}],
					code: [{
						required: true,
						message: '请输入图形验证码',
						trigger: 'blur'
					},],
				},
				perfectRuleForm: {
					company: '',
					password: ''
				},
				perfectRules: {
					company: [{
						required: true,
						message: '请输入您的公司/店铺名称',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请设置登录密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码位数不正确',
						trigger: 'blur'
					}],
				},
				yanzhengmaLoginRuleForm: {
					phone: '',
					captcha: '',
					code: ''
				},
				yanzhengmaLoginRules: {
					phone: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 11,
						max: 11,
						message: '手机号位数不正确',
						trigger: 'blur'
					}],
					captcha: [{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 6,
						max: 6,
						message: '短信验证码位数不正确',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入图形验证码',
						trigger: 'blur'
					}],
				},
				forgetRuleForm: {
					phone: '',
					password: '',
					captcha: '',
					passwordConfirm: '',
					code: ''
				},
				forgetRules: {
					phone: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 11,
						max: 11,
						message: '手机号位数不正确',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请设置登录密码',
						trigger: ['blur','change'],
						validator: passwordChecked
					}, {
						min: 6,
						max: 20,
						message: '密码位数不正确',
						trigger: 'blur'
					}],
					captcha: [{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 6,
						max: 6,
						message: '短信验证码位数不正确',
						trigger: 'blur'
					}],
					passwordConfirm: [{
						required: true,
						trigger: ['blur','change'],
						validator: passwordConfirmChecked
					}],
					code: [{
						required: true,
						message: '请输入图形验证码',
						trigger: 'blur'
					},],
				},
				accountTab: ['主账号登录','子账号登录'],
				isSubAccountFlag: 0, // 主账号0，子账号1
				captchaImage: '',
				uuid: ''

			}
		},
		created() {
			this.type = this.typeNum || 1
			this.rememberUser()
			this.getCaptchaImage()
		},
		mounted() {

		},
		methods: {
			changeIsSubAccountFlag(index) {
				this.getCaptchaImage()
				this.isSubAccountFlag = index
			},
			getCaptchaImage() {
				CaptchaImage().then(res => {
					this.captchaImage = "data:image/gif;base64," + res.img
					this.uuid = res.uuid
				})
			},
			changeType(type) {
				this.getCaptchaImage()
				this.type = type
			},
			changeLoginType(type) {
				this.loginType = type
			},
			changeProcess(type) {
				console.log('this.vCodeRuleForm: ', this.vCodeRuleForm);
				if (type == 2) {
					this.$refs.vCodeRuleForm.validate((valid) => {
						if (valid) {
							this.process = 2
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				} else if (type == 3) {
					this.$refs.perfectRuleForm.validate((valid) => {
						if (valid) {
							this.register()
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				}
			},
			toIndex() {
				this.$router.push({
					name: 'index'
				})
			},
			//获取记住的账号密码 （此处需要对密码进行加密)
			rememberUser() {
				if (this.$store.state.userName && this.$store.state.password) {
					this.accountRuleForm.checked = true
					if (this.$store.state.isSubAccountFlag == 0) {
						this.isSubAccountFlag = 0
						this.accountRuleForm.phone = this.$store.state.userName
						this.accountRuleForm.phonePassword = this.$store.state.password
					} else {
						this.isSubAccountFlag = 1
						this.accountRuleForm.account = this.$store.state.userName
						this.accountRuleForm.accountPassword = this.$store.state.password
					}
				}
				
			},
			// 登录
			login() {
				if (this.type == 1 && this.loginType == 1) {
					this.$refs.accountRuleForm.validate((valid) => {
						console.log('valid: ',valid);
						if (valid) {
							let data = {
								password: '',
								phone: '',
								account: '',
								isSubAccount: this.isSubAccountFlag
							}
							
							if (this.isSubAccountFlag == 0) {
								data.phone = this.accountRuleForm.phone
								data.password = this.accountRuleForm.phonePassword
							} else if (this.isSubAccountFlag == 1) {
								data.account = this.accountRuleForm.account
								data.password = this.accountRuleForm.accountPassword
							}
							
							Login(data).then(res => {
								this.toast({
									message: '登录成功',
									type: 'success',
									duration: 1500
								})
								this.$store.dispatch('updateToken', res.data.token)
								if (this.accountRuleForm.checked) {
									this.$store.commit('changeUserRemember', {
										isSubAccountFlag: data.isSubAccount,
										userName: data.phone ||  data.account,
										password: data.password
									})
								}
								
								this.toIndex()
								
							})
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				} else if(this.type == 1 && this.loginType == 2) {
					this.$refs.yanzhengmaLoginRuleForm.validate((valid) => {
						if (valid) {
							let data = {
								phone: this.yanzhengmaLoginRuleForm.phone,
								captcha: this.yanzhengmaLoginRuleForm.captcha,
								isSubAccount: this.isSubAccountFlag
							}
							LoginMobile(data).then(res => {
								this.toast({
									message: '登录成功',
									type: 'success'
								})
								this.$store.dispatch('updateToken', res.data.token)
								this.toIndex()
								
							})
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				}
			},
			// 注册
			register() {
				let data = {
					phone: this.vCodeRuleForm.phone,
					captcha: this.vCodeRuleForm.captcha,
					company: this.perfectRuleForm.company,
					password: this.perfectRuleForm.password
				}
				Register(data).then(res => {
					this.$store.dispatch('updateToken', res.data.token)
					this.process = 3
				})
			},
			// 忘记密码
			updateForgetConfirm() {
				this.$refs.forgetRuleForm.validate((valid) => {
					if (valid) {
						let data = {
							phone: this.forgetRuleForm.phone,
							password: this.forgetRuleForm.password,
							captcha: this.forgetRuleForm.captcha
						}
						UpdateForget(data).then(res => {
							this.toast({
								message: '修改成功',
								type: 'success'
							})
							this.changeType(1)
							this.changeLoginType(1)
							
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				
			},
			getCode() {
				if (this.type == 1 && this.loginType == 2) { // 验证码登录
					let validateFieldList= []
					this.$refs.yanzhengmaLoginRuleForm.validateField(['phone','code'], (valid) => {
						if (valid) {
							console.log(valid);
					
						} else {
							console.log(valid);
							validateFieldList.push(valid)
							if (validateFieldList.length == 2 && validateFieldList.every((item) => item === "")) {
							  // 之后的操作
							  if (this.$refs.yanzhengmaLoginCountdown.canGetCode) {
							  	let data = {
							  		phone: this.yanzhengmaLoginRuleForm.phone,
							  		code: this.yanzhengmaLoginRuleForm.code,
							  		uuid: this.uuid,
							  		type: 'login'
							  	}
							  	SendCode(data).then(res => {
							  		this.$refs.yanzhengmaLoginCountdown.start()
							  	}).catch(err => {
									this.getCaptchaImage()
								})
							  }
							}
						}
					});
				} else if (this.type == 2 && this.process == 1) { // 注册
					let validateFieldList= []
					this.$refs.vCodeRuleForm.validateField(['phone','code'], (valid) => {
						if (valid) {
							console.log(valid);
					
						} else {
							console.log(valid);
							validateFieldList.push(valid)
							if (validateFieldList.length == 2 && validateFieldList.every((item) => item === "")) {
							  // 之后的操作
							  if (this.$refs.vCodeCountdown.canGetCode) {
							  	let data = {
							  		phone: this.vCodeRuleForm.phone,
									code: this.vCodeRuleForm.code,
									uuid: this.uuid,
							  		type: 'register'
							  	}
							  	SendCode(data).then(res => {
							  		this.$refs.vCodeCountdown.start()
							  	}).catch(err => {
									this.getCaptchaImage()
								})
							  }
							}
						}
					});
				} else if (this.type == 3) { // 忘记密码
					let validateFieldList= []
					this.$refs.forgetRuleForm.validateField(['phone','code'], (valid) => {
						if (valid) {
							console.log(valid);
					
						} else {
							console.log(valid);
							validateFieldList.push(valid)
							if (validateFieldList.length == 2 && validateFieldList.every((item) => item === "")) {
							  // 之后的操作
							  if (this.$refs.forgetCountdown.canGetCode) {
							  	let data = {
							  		phone: this.forgetRuleForm.phone,
									code: this.forgetRuleForm.code,
									uuid: this.uuid,
							  		type: 'forget'
							  	}
							  	SendCode(data).then(res => {
							  		this.$refs.forgetCountdown.start()
							  	}).catch(err => {
									this.getCaptchaImage()
								})
							  }
							}
						}
					});
				}
				

			},
			openPopRichText(key) {
				let data = {
					title: '',
					content: ''
				}
				if (key == 1) {
					data.title = '隐私协议'
				} else if (key == 2) {
					data.title = '售后协议'
				} else if (key == 3) {
					data.title = '服务质量承诺'
				} else if (key == 4) {
					data.title = '关于我们'
				}
				ConfigAgreement(key).then(res => {
					data.content = res.data.content
					this.$rootApp.$refs.PopRichText.open({
						...data
					})
				})
				
			},
			

		}
	}
</script>

<style lang="scss" scoped>
	.verify-image {
		margin: 0 0 0 10rpx;
		width: 150px;
		height: 58px;
		position: absolute;
		right: 0;
		top: 0;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.login {
		flex: 1;
		display: flex;
		box-sizing: border-box;
		width: 100%;
		box-sizing: border-box;
		background-color: #FFFFFF !important;
		padding: 20px 0 20px 50px;

		.login-left {
			display: flex;
			align-items: center;
			.bg {
				width: 650px;
				// width: 685px;
				height: 805px;
				// height: 850px;
				background-image: url('../assets/085.png');
				background-size: 100% 100%;
			}
		}

		.login-right {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			padding: 0 20px;

			.login-form {
				display: flex;
				flex-direction: column;
				width: 510px;
				// width: 540px;

				.lf-heading {
					font-size: 24px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					height: 66px;
					line-height: 66px;
					border-bottom: 1px solid #f6f6f6;
				}

				.lf-tab {
					align-self: center;
					margin-top: 30px;
					margin-bottom: 30px;
					height: 60px;
					display: flex;
					align-items: center;
					position: relative;

					.lft-item {
						width: 150px;
						height: 60px;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						.lft-text {
							font-size: 20px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: bold;
							color: #333333;
						}

						&.active {
							.lft-text {
								color: #3571DA;
							}
							.lft-under-line {
								display: initial;
							}
						}
						
						.lft-vertical-line {
							width: 2px;
							height: 20px;
							background-color: #d1d1d1;
							position: absolute;
							right: 0;
						}
						
						.lft-under-line {
							display: none;
							position: absolute;
							width: 38px;
							height: 4px;
							background: #3571DA;
							border-radius: 2px 2px 2px 2px;
							bottom: 0;
							left: 50%;
							transform: translate(-50%, 0);
						}
					}
				}

				.lf-process {
					height: 150px;
					display: flex;
					align-items: center;

					.lfp-item {
						display: flex;
						align-items: center;

						.lfpi-num {
							width: 40px;
							height: 40px;
							border-radius: 50%;
							box-sizing: border-box;
							border: 2px solid #B4B9BF;
							margin-right: 16px;
							font-size: 20px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: bold;
							color: #8D9094;
							line-height: 36px;
							text-align: center;
						}

						.lfpi-text {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #8D9094;
						}

						&.active {
							.lfpi-num {
								border: none;
								background-color: #3571DA;
								color: #FFFFFF;
								line-height: 40px;
							}

							.lfpi-text {
								color: #18191A;
							}
						}

						&.ok {
							.lfpi-num {
								border-color: #3571DA;
								background-image: url('../assets/007.png');
								background-size: 20px 20px;
								background-position: center center;
								background-repeat: no-repeat;
							}

							.lfpi-text {
								color: #18191A;
							}
						}
					}

					.lfp-line {
						flex: 1;
						width: 50px;
						height: 4px;
						background: #CED4DB;
						border-radius: 0px 0px 0px 0px;
						margin: 0 16px;

						&.ok {
							background: #3571DA;
						}
					}
				}

				.lf-success-icon {
					width: 80px;
					height: 80px;
					background-image: url('../assets/009.png');
					background-size: 100% 100%;
					margin-top: 60px;
					align-self: center;
				}

				.lf-success-text {
					margin-top: 20px;
					font-size: 24px;
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					color: #333333;
					margin-bottom: 85px;
					align-self: center;
				}

				.lf-xiadan-btn {
					width: 320px;
					height: 58px;
					background-color: #3571DA;
					border-radius: 10px;
					font-size: 20px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 58px;
					text-align: center;
					align-self: center;
				}

				.el-form-item {}

				.lf-input-box {
					display: flex;
					position: relative;
					
					&.yanzhengma {
						position: relative;
						/deep/.lib-input {
							input {
								// width: 310px;
								// padding-right: 52px;
							}
							.el-input__suffix {
								margin-right: 120px;
							}
						}
					
						.yanzhengma-btn {
							position: absolute;
							right: 20px;
							top: 0;
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: bold;
							color: #3571DA;
							line-height: 58px;
							width: 80px;
							text-align: center;
						}
					}

					.lib-icon {
						z-index: 1;
						margin-left: 20px;
						margin-right: 20px;
						width: 30px;
						height: 30px;
						align-self: center;
						position: absolute;
						left: 0;
						background-size: 100% 100%;

						&.phone {
							background-image: url('../assets/002.png');
						}

						&.lock {
							background-image: url('../assets/003.png');
						}

						&.safe {
							background-image: url('../assets/004.png');
						}

						&.people {
							background-image: url('../assets/010.png');
						}

						&.edit {
							background-image: url('../assets/011.png');
						}
					}

					/deep/.lib-input {

						input {
							width: 510px;
							// width: 540px;
							height: 58px;
							padding-left: 70px;
							padding-right: 30px;
							background-color: #E7F0FF;
							border-radius: 10px;
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;

							&::placeholder {
								color: #A2ACBC !important;
							}
						}

						.el-input__suffix {
							display: flex;
							align-items: center;

							.el-input__icon {
								font-size: 20px;
								margin: 5px;
							}
						}
					}

					.lib-verification-tip {}

					.lib-tip {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #3571DA;
						padding: 0 20px;
						position: absolute;
						right: 0;
						align-self: center;
					}

					.lib-arrow {
						width: 12px;
						height: 12px;
						position: absolute;
						right: 20px;
						align-self: center;
						background-size: 100% 100%;
						background-image: url('../assets/008.png');
					}

					.lib-input-tip {
						position: absolute;
						top: calc(100% + 20px);
						font-size: 16px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						color: #3571DA;
					}

					// &.box-1 {
					// 	margin-bottom: 40px;
					// }

					// &.box-2 {
					// 	margin-bottom: 30px;
					// }

					// &.box-3 {
					// 	margin-bottom: 40px;
					// }

					// &.box-4 {
					// 	margin-bottom: 30px;
					// }

					// &.box-5 {
					// 	margin-top: 50px;
					// 	margin-bottom: 40px;
					// }

					// &.box-6 {
					// 	margin-bottom: 40px;
					// }

					// &.box-7 {
					// 	margin-bottom: 66px;
					// }

					// &.box-8 {
					// 	margin-bottom: 70px;
					// }

					// &.box-9 {
					// 	margin-bottom: 40px;
					// }

					// &.box-10 {
					// 	margin-bottom: 30px;
					// }

					// &.box-11 {
					// 	margin-bottom: 40px;
					// }

					// &.box-12 {
					// 	margin-bottom: 40px;
					// }

					&.box-13 {
						// margin-bottom: 115px;

						.lib-input-tip {
							top: calc(100% + 30px);
						}
					}

					&.phone {}

					&.password {
						/deep/.lib-input {
							input {
								padding-right: 80px;
							}
						}
					}

					&.verification-code {
						.lib-input {
							// padding-right: 120px;
						}
					}

					&.select {
						/deep/.lib-input {
							input {
								padding-right: 52px;
							}
						}
					}
				}

				.el-form-item {
					&.box-1 {
						margin-bottom: 40px;
					}

					&.box-2 {
						margin-bottom: 30px;
					}
					
					&.box-3 {
						margin-bottom: 40px;
					}
					
					&.box-4 {
						margin-bottom: 30px;
					}
					
					&.box-5 {
						margin-top: 50px;
						margin-bottom: 40px;
					}
					
					&.box-6 {
						margin-bottom: 40px;
					}
					
					&.box-7 {
						margin-bottom: 66px;
					}
					
					&.box-8 {
						margin-bottom: 70px;
					}

					&.box-9 {
						margin-bottom: 40px;
					}

					&.box-10 {
						margin-bottom: 30px;
					}

					&.box-11 {
						margin-bottom: 40px;
					}

					&.box-12 {
						margin-bottom: 40px;
					}

					&.box-13 {
						margin-bottom: 115px;
					}
				}

				.lf-tools {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 70px;

					.lft-remember-password {
						display: flex;
						align-items: center;
						height: 25px;

						.checked-Box {
							display: flex;
							align-items: center;

							.cb-icon {
								width: 14px;
								height: 14px;
								display: flex;

								.cbi-no {
									width: 14px;
									height: 14px;
									background-image: url('../assets/005.png');
									background-size: 100% 100%;
								}

								.cbi-yes {
									width: 14px;
									height: 14px;
									background-image: url('../assets/006.png');
									background-size: 100% 100%;
								}
							}

							.cb-label {
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								height: 25px;
								line-height: 25px;
							}
						}
					}

					.lft-right {
						display: flex;
						align-items: center;

						.lftr-forget-password {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
						}

						.lftr-line {
							width: 2px;
							height: 14px;
							background-color: #d5d5d5;
							margin: 0 10px;
						}

						.lftr-verification-code-login {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
						}
					}

					.lft-protocol {
						display: flex;
						align-items: center;

						.checked-Box {
							display: flex;
							align-items: center;

							.cb-icon {
								width: 14px;
								height: 14px;
								display: flex;

								.cbi-no {
									width: 14px;
									height: 14px;
									background-image: url('../assets/005.png');
									background-size: 100% 100%;
								}

								.cbi-yes {
									width: 14px;
									height: 14px;
									background-image: url('../assets/006.png');
									background-size: 100% 100%;
								}
							}

							/deep/.el-checkbox {
								display: flex;
								align-items: center;

								.el-checkbox__input {
									display: flex;
									align-items: center;
								}
							}

							.cb-label {
								height: 25px;
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 25px;

								.highlight {
									color: #3571DA;
								}
							}
						}
					}
				}

				.lf-login-btn {
					width: 100%;
					height: 58px;
					font-size: 20px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 58px;
					text-align: center;
					background-color: #3571DA;
					border-radius: 10px;
				}

				.lf-toRegister {
					margin-top: 40px;
					font-size: 20px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					align-self: center;

					.lft-highlight {
						color: #3571DA;
					}
				}
			}
		}
	}
</style>