<template>
	<div class="orderDetail">
		<div class="card">
			<div class="c-header" v-if="$route.name == 'orderDetail'">
				<div class="ch-left">
					<div class="chl-icon"></div>
					<div class="chl-text btn-text" @click="openHome">首页</div>
					<div class="chl-line">/</div>
					<div v-if="$route.query.from == 'orderPay'" class="chl-text btn-text" @click="$router.push({name: 'order'})">我的订单</div>
					<div v-else class="chl-text btn-text" @click="back()">我的订单</div>
					<div class="chl-line">/</div>
					<div class="chl-text">订单详情</div>
				</div>
				<div class="ch-right btn-fill" @click="back()">返回></div>
			</div>
			<div class="c-header" v-if="$route.name == 'afterSaleOrderDetail'">
				<div class="ch-left">
					<div class="chl-icon"></div>
					<div class="chl-text btn-text" @click="openHome">首页</div>
					<div class="chl-line">/</div>
					<div class="chl-text btn-text" @click="back(-2)">售后管理</div>
					<div class="chl-line">/</div>
					<div class="chl-text btn-text" @click="back()">售后详情</div>
					<div class="chl-line">/</div>
					<div class="chl-text btn-text">工单详情</div>
				</div>
				<div class="ch-right btn-fill" @click="back()">返回></div>
			</div>
			<div class="c-body" v-loading="!order || !order.id">
				<div class="process">
					<div class="progress-item" :class="{ success: order.status > 0 }">
						<div class="pi-num">1</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">发布需求</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{ success: order.status >= 200, active: order.status == 100 }">
						<div class="pi-num">2</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">完成付款</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{ success: order.status >= 300, active: order.status == 200 || order.status == 300 || order.status == 400 }">
						<div class="pi-num">3</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">师傅接单</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{ success: order.status > 500, active: order.status == 500 }">
						<div class="pi-num">4</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">
							<div>师傅施工</div>
						</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{ success: order.status >= 700, active: order.status == 600 }">
						<div class="pi-num">5</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">等待验收</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{ success: order.status >= 700 }">
						<div class="pi-num">6</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">订单完成</div>
					</div>
				</div>
				<div class="log">
					<div class="log-content">
						<div class="tip" v-if="order.status == 100">您的订单未付款，请尽快付款!</div>
						<div class="log-list">
							<div
								class="ll-item"
								:class="{
									show:
										omsOperateRecordListindex == 0 ||
										omsOperateRecordListitem.relevanceId != order.omsOperateRecordList[omsOperateRecordListindex - 1].relevanceId
								}"
								v-for="(omsOperateRecordListitem, omsOperateRecordListindex) in order.omsOperateRecordList"
								:key="`omsOperateRecordList-${omsOperateRecordListindex}`"
							>
								<div class="lli-time">
									{{ $moment(omsOperateRecordListitem.createTime).format('YYYY-MM-DD HH:mm:ss') }}
								</div>
								<!--                关联类型 1=远程费信息 2=维修材料 3=安装材料 4=完工材料 5=保外付款 6=返件信息 7=促好评 8=余额支付-->
								<div class="lli-content" v-if="omsOperateRecordListitem.relevanceType == 1">
									{{ omsOperateRecordListitem.content }}
									<span class="blue" @click="openPopYuanchengfei(omsOperateRecordListitem)">远程费信息></span>
								</div>
								<div class="lli-content" v-else-if="omsOperateRecordListitem.relevanceType == 2 || omsOperateRecordListitem.relevanceType == 3">
									{{ omsOperateRecordListitem.content }}
									<span class="blue" @click="openWeixiucailiao(omsOperateRecordListitem.relevanceId)">查看材料></span>
									<!-- <span class="blue" v-if="submissionMDetail && submissionMDetail.length"
										@click="$refs.wuliu.open(submissionMDetail[0].trackingNum,submissionMDetail[0].company)">物流信息></span> -->
								</div>
								<div class="lli-content" v-else-if="omsOperateRecordListitem.relevanceType == 4">
									{{ omsOperateRecordListitem.content }}
									<span class="blue" @click="$refs.wangong.open(omsOperateRecordListitem.relevanceId)">完工材料></span>
								</div>
								<div class="lli-content" v-else-if="omsOperateRecordListitem.relevanceType == 6">
									{{ omsOperateRecordListitem.content }}
									<span class="red" v-if="order.paidFlag">（客服垫付）</span>
									<span class="blue" @click="$refs.fanjian.open(orderId, omsOperateRecordListitem.relevanceId, order)">返件信息></span>
									<span class="blue" @click="openPopOrderWuliu(omsOperateRecordListitem.relevanceId)">物流信息></span>
								</div>
								<div class="lli-content" v-else-if="omsOperateRecordListitem.relevanceType == 7">
									{{ omsOperateRecordListitem.content }}
									<span class="blue" @click="$refs.cuhaoping.open(orderId, omsOperateRecordListitem.relevanceId)">促好评信息></span>
								</div>
								<div class="lli-content" v-else-if="omsOperateRecordListitem.relevanceType == 10">
									{{ omsOperateRecordListitem.content }}
									<span class="blue" @click="toAfterSaleDetail(omsOperateRecordListitem)">查看详情></span>
								</div>
								<div class="lli-content" v-else-if="omsOperateRecordListitem.relevanceType == 11">
									{{ omsOperateRecordListitem.content }}
									<span class="blue" @click="checkPeiJianWuLiu(omsOperateRecordListitem)">物流信息></span>
								</div>
								<div class="lli-content" v-else-if="omsOperateRecordListitem.relevanceType == 12" style="display: flex">
									{{ omsOperateRecordListitem.content }}
									<Media model="custom" v-if="omsOperateRecordListitem.certificate" :value="omsOperateRecordListitem.certificate" key="certificate">
										<span class="blue" v-if="omsOperateRecordListitem.certificate">付款截图</span>
									</Media>
								</div>
								<div class="lli-content" v-else-if="omsOperateRecordListitem.relevanceType == 13" style="display: flex">
									{{ omsOperateRecordListitem.content }}
									<Media model="custom" v-if="omsOperateRecordListitem.certificate" :value="omsOperateRecordListitem.certificate" key="certificate">
										<span class="blue" v-if="omsOperateRecordListitem.certificate">图片</span>
									</Media>
								</div>
								<div class="lli-content" v-else style="display: flex">
									<span>{{ omsOperateRecordListitem.content }}</span>
									<Media model="custom" v-if="omsOperateRecordListitem.certificate" :value="omsOperateRecordListitem.certificate" key="certificate">
										<span class="blue" v-if="omsOperateRecordListitem.certificate">用户通话截屏</span>
									</Media>
								</div>
							</div>
						</div>
					</div>
					<div class="options">
						<div class="btn" v-if="order.statusInfo.isPay" @click="toOrderPay(order)">立即付款</div>
						<div class="btn" v-if="order.statusInfo.isReminder" @click="openPopOrderReminder(order)">催单</div>
						<div class="btn" v-if="hasPermi('app:order:cancel') && order.statusInfo.isCancell" @click="openOrderCancel(order)">取消订单</div>
						<div class="btn" v-if="hasPermi('app:order:speed') && order.statusInfo.isUrgent" @click="openPopOrderUrgent(orderListitem)">加急</div>
						<div class="btn" v-if="order.statusInfo.isApprovalAccessories" @click="openWeixiucailiao()" @success="getOrderDetail()">配件发货</div>
						<div class="btn" v-if="order.statusInfo.isFanJian" @click="$refs.fanjian.open(orderId, null, order)">返件审核</div>
						<div class="btn" v-if="order.statusInfo.isPromotePositive" @click="$refs.cuhaoping.open(orderId)">促好评审批</div>
						<div class="btn" v-if="hasPermi('app:order:confirmation') && order.statusInfo.isCompletionConfirmation" @click="setOrderConfirmation(order)">完工确认</div>
						<div class="btn" v-if="hasPermi('app:order:after') && order.statusInfo.isAfterSales" @click="openPopAfterSalesApply(order)">申请售后</div>
						<div class="btn" v-if="hasPermi('app:order:add')" @click="toOrderCreate(order)">再次下单</div>
						<div class="btn" v-if="order.customerServiceId" @click="openPopCustomerService(order)">联系客服</div>
					</div>
				</div>
				<div class="cb-title">
					<div class="cbt-text">客户信息</div>
					<div class="cbt-edit btn-fill" v-if="hasPermi('app:order:modify') && order.status <= 400" @click="openPopOrderCustomerModify">修改</div>
				</div>
				<div class="kehu-info">
					<div class="ki-field">
						<div class="kif-label">客户信息：</div>
						<div class="kif-value">
							{{ order.realName }}；{{ order.phone }}{{ order.phoneExtension | phoneExtensionFilter }}；{{ order.provinceName }}{{ order.cityName
							}}{{ order.districtName }}{{ order.detailAddress }}
						</div>
					</div>
					<div class="ki-field">
						<div class="kif-label">上门期望：</div>
						<div class="kif-value">{{ order.doorTime ? order.doorTime : '--' }}</div>
					</div>
					<div class="ki-field">
						<div class="kif-label">发单人备注：</div>
						<div class="kif-value">{{ order.remarks || '无' }}</div>
					</div>
				</div>
				<div class="cb-title shangping">
					<div class="cbt-text">产品信息</div>
				</div>
				<div class="list-header">
					<div class="lh-item">产品信息</div>
					<div class="lh-item">数量</div>
					<div class="lh-item">{{ order.parole == 1 ? '产品单价' : '指导单价' }}</div>
					<div class="lh-item">{{ order.parole == 1 ? '产品总价' : '指导总价' }}</div>
				</div>
				<div class="list-item" v-for="(productListitem, productListindex) in order.productList" :key="`productList-${productListindex}`">
					<div class="li-column">
						<div class="column-1">
							<div class="c1-pic">
								<Media mediaClass="w100h100g20b4" :value="productListitem.productPicture"></Media>
							</div>
							<div class="c1-content">
								<div class="c1c-name">
									{{ `${productListitem.categoryName}(${productListitem.modelName})` }}
								</div>
								<div class="c1c-field">
									<div class="c1cf-label">服务项目：</div>
									<div class="c1cf-value">{{ order.serviceType | orderServiceTypeFilter }}</div>
								</div>
								<div class="c1c-field">
									<div class="c1cf-label">安维说明：</div>
									<div class="c1cf-value">
										{{ productListitem.remarks ? productListitem.remarks : '无' }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="li-column">
						<div class="column-2">
							<div class="c2-num">{{ productListitem.number }}</div>
						</div>
					</div>
					<div class="li-column">
						<div class="column-3">
							<div class="c3-price" v-if="order.parole == 1">
								{{ productListitem.price ? `￥${productListitem.price}` : '--' }}
							</div>
							<div class="c3-price" v-if="order.parole == 2">
								{{ productListitem.guidePrice ? `￥${productListitem.guidePrice}` : '--' }}
							</div>
						</div>
					</div>
					<div class="li-column">
						<div class="column-4">
							<div class="c4-total" v-if="order.parole == 1">
								{{ productListitem.price ? `￥${productListitem.price * productListitem.number}` : '--' }}
							</div>
							<div class="c4-total" v-if="order.parole == 2">
								{{ productListitem.guidePrice ? `￥${productListitem.guidePrice * productListitem.number}` : '--' }}
							</div>
						</div>
					</div>
				</div>
				<div class="list-footer">
					<template v-if="order.serviceType == 5">
						<div class="lf-total pointer">
							<div class="lft-label">合计金额：</div>
							<div class="lft-money">￥0</div>
						</div>
					</template>
					<template v-else>
						<el-popover placement="top" width="" trigger="hover">
							<div class="feiyongmingxi">
								<div
									class="fy-item"
									v-for="(omsOrderExpensesitem, omsOrderExpensesindex) in order.omsOrderExpenses"
									:key="`omsOrderExpenses-${omsOrderExpensesindex}`"
								>
									<div class="fyi-label">{{ omsOrderExpensesitem.name }}</div>
									<div class="fyi-value">
										<template v-if="omsOrderExpensesitem.type == 1">￥{{ omsOrderExpensesitem.price }}</template>
										<template v-if="omsOrderExpensesitem.type == 2">-￥{{ omsOrderExpensesitem.price }}</template>
									</div>
								</div>
							</div>
							<div class="lf-total pointer" slot="reference">
								<div class="lft-label">合计金额：</div>
								<div class="lft-money">￥{{ order.realPayPrice || order.totalPrice }}</div>
								<div class="lft-arrow"></div>
							</div>
						</el-popover>
					</template>
				</div>
				<template v-if="order.logisticsList && order.logisticsList.length">
					<div class="cb-title">
						<div class="cbt-text">物流信息</div>
					</div>
					<div class="kehu-info" v-for="(logisticsListitem, logisticsListindex) in order.logisticsList" :key="logisticsListindex">
						<div class="ki-field">
							<div class="kif-label">物流公司：</div>
							<div class="kif-value">
								{{ logisticsListitem.courierCompany }}
							</div>
						</div>
						<div class="ki-field">
							<div class="kif-label">物流单号：</div>
							<div class="kif-value">
								{{ logisticsListitem.courierNum }}
								<span class="blue" @click="$refs.wuliu.open(logisticsListitem.courierNum, logisticsListitem.courierCompany)">查看></span>
							</div>
						</div>
					</div>
				</template>
				<div class="cb-title">
					<div class="cbt-text">订单信息</div>
				</div>
				<div class="kehu-info">
					<div class="ki-field">
						<div class="kif-label">订单编号：</div>
						<div class="kif-value">{{ order.orderNo }}</div>
					</div>
					<div class="ki-field">
						<div class="kif-label">下单账号：</div>
						<div class="kif-value">{{ order.userInfo.name }}</div>
					</div>
					<div class="ki-field">
						<div class="kif-label">下单时间：</div>
						<div class="kif-value">{{ order.createTime }}</div>
					</div>
					<div class="ki-field" v-if="order.payTime">
						<div class="kif-label">付款时间：</div>
						<div class="kif-value">{{ order.payTime }}</div>
					</div>
					<div class="ki-field" v-if="order.receivingTime">
						<div class="kif-label">师傅接单时间：</div>
						<div class="kif-value">{{ order.receivingTime }}</div>
					</div>
					<div class="ki-field" v-if="order.parole">
						<div class="kif-label">订单类型：</div>
						<div class="kif-value" v-if="order.parole == 1">保内</div>
						<div class="kif-value" v-if="order.parole == 2">保外</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 修改客户信息 -->
		<PopOrderCustomerModify ref="PopOrderCustomerModify" :show:sync="showPopOrderCustomerModify" @success="getOrderDetail"></PopOrderCustomerModify>
		<!-- 加急 -->
		<PopOrderUrgent ref="PopOrderUrgent" :show:sync="showPopOrderUrgent" @success="getOrderDetail"></PopOrderUrgent>
		<!-- 联系客服 -->
		<PopCustomerService ref="PopCustomerService" :show:sync="showPopCustomerService"></PopCustomerService>
		<!-- 订单催单 -->
		<PopOrderReminder ref="PopOrderReminder" :show:sync="showPopOrderReminder" @success="getOrderDetail"></PopOrderReminder>
		<!-- 取消订单提示 -->
		<PopTip ref="OrderCancel" :show.sync="showOrderCancel">
			<div class="pop-body OrderCancel" slot="default" slot-scope="{ popData }">
				<div class="pb-icon"></div>
				<div class="pb-content">是否确认取消订单？</div>
				<div class="pb-fields" v-for="(item, index) in popData.orderList" :key="`orderList-${index}`">
					<div class="pbf-item">订单号:{{ item.orderNo }}</div>
					<div class="pbf-item">下单时间: {{ $moment(item.createTime).format('YYYY-MM-DD HH:mm') }}</div>
				</div>
			</div>
		</PopTip>

		<!-- 远程费信息 -->
		<PopYuanchengfei ref="PopYuanchengfei" :show:sync="showPopYuanchengfei"></PopYuanchengfei>

		<!-- 安装/维修材料 -->
		<WeixuiPop ref="weixiucailiao" @success="getOrderDetail"></WeixuiPop>

		<!-- 完工信息 -->
		<PopOrderWangong ref="wangong"></PopOrderWangong>

		<!-- 返件申请 -->
		<PopOrderFanjian ref="fanjian" @success="getOrderDetail"></PopOrderFanjian>

		<!-- 促好评 -->
		<PopOrderCuhaoping ref="cuhaoping" @success="getOrderDetail"></PopOrderCuhaoping>

		<!-- 物流信息 -->
		<PopOrderWuliu ref="wuliu"></PopOrderWuliu>

		<!-- 申请售后 -->
		<PopAfterSalesApply ref="PopAfterSalesApply" :show:sync="showPopAfterSalesApply" @applySuccess="getOrderDetail"></PopAfterSalesApply>

		<!-- 取消订单填写备注 -->
		<PopTextarea ref="PopTextarea" :show:sync="showPopTextarea"></PopTextarea>
	</div>
</template>

<script>
import { OrderDetail, OrderCancel, getSubmissionM, information, OrderConfirmation, PeiJianWuLiuInformation, OrderPay } from '../common/api.js';
import PopOrderCustomerModify from '../components/PopOrderCustomerModify.vue';
import PopOrderUrgent from '../components/PopOrderUrgent.vue';
import PopCustomerService from '../components/PopCustomerService.vue';
import PopOrderReminder from '../components/PopOrderReminder.vue';
import PopYuanchengfei from '../components/PopYuanchengfei.vue';
import WeixuiPop from '@/components/WeixiuPop';
// import order from "@/views/order";
import PopOrderWangong from '@/components/PopOrderWangong';
import PopOrderFanjian from '@/components/PopOrderFanjian';
import PopOrderCuhaoping from '@/components/PopOrderCuhaoping';
import PopOrderWuliu from '@/components/PopOrderWuliu';
import PopAfterSalesApply from '@/components/PopAfterSalesApply';
import PopTextarea from '@/components/PopTextarea';

export default {
	components: {
		PopOrderFanjian,
		PopOrderWangong,
		WeixuiPop,
		PopOrderCustomerModify,
		PopCustomerService,
		PopOrderReminder,
		PopYuanchengfei,
		PopOrderCuhaoping,
		PopOrderUrgent,
		PopOrderWuliu,
		PopAfterSalesApply,
		PopTextarea
	},
	props: ['orderId'],
	data() {
		return {
			peijianwuliuVisible: false,
			order: {
				// 订单详情
				// status: '', // 订单状态 待付款100,待接单200,待预约300,待施工400,施工中500,待验收600,已完成700,已关闭800,售后900
				userInfo: {}, //
				statusInfo: {} //
				// omsOperateRecordList: [操作记录
				// 	{
				// 		relevanceType: 关联类型 1=远程费信息 2=维修材料 3=安装材料 4=完工材料 5=保外付款 6=返件信息 7=促好评 8=余额支付
				// 	}
				// ]
			},
			showPopOrderCustomerModify: false,
			showPopCustomerService: false,
			showPopOrderReminder: false,
			showPopYuanchengfei: false,
			showPopOrderUrgent: false,
			showOrderCancel: false,
			showPopAfterSalesApply: false,
			showPopTextarea: false,

			submissionMDetail: [],
			isReady: false
		};
	},
	watch: {
		orderId() {
			this.refresh();
		}
	},
	created() {
		this.getOrderDetail();
		this.getSubmissionM();
	},
	mounted() {},
	methods: {
		refresh() {
			this.getOrderDetail();
			this.getSubmissionM();
		},
		checkPeiJianWuLiu(omsOperateRecordListitem) {
			PeiJianWuLiuInformation(omsOperateRecordListitem.relevanceId).then((res) => {
				this.$refs.wuliu.open(res.data.logisticsNum, res.data.logisticsCompany);
			});
		},
		getOrderDetail() {
			OrderDetail(this.orderId).then((res) => {
				this.order = res.data;
				// 虚拟接单数据
				if (this.order.virtualTaking == 1) {
					if (!this.order.doorTime) {
						this.order.node = 310;
						this.order.status = 300;
					} else {
						this.order.node = 510;
						this.order.status = 500;
					}
				}
			});
		},
		getSubmissionM() {
			getSubmissionM(this.orderId).then((res) => {
				this.submissionMDetail = res.data;
			});
		},
		openPopOrderWuliu(fjId) {
			information(fjId).then((res) => {
				this.$refs.wuliu.open(res.data.expressNumber, res.data.expressCompanyName);
			});
		},
		openPopOrderCustomerModify() {
			this.$refs.PopOrderCustomerModify.open(this.order);
		},
		back(delta = -1) {
			console.log('delta: ', delta);
			// this.$router.push({name: 'order'})
			this.$router.go(delta);
		},
		/**
		 * 安装维修材料提交
		 */
		openWeixiucailiao(materialSubmissionId = '') {
			this.$refs.weixiucailiao.open(this.orderId, this.order.serviceType, materialSubmissionId);
		},
		setOrderConfirmation(orderListitem) {
			this.$rootApp.$refs.PopTip.open({
				content: '是否确认已经完工？',
				type: 'warning',
				confirm: () => {
					OrderConfirmation(orderListitem.id).then((res) => {
						this.getOrderDetail();
					});
				}
			});
		},
		openPeijianwuliuVisible() {
			this.peijianwuliuVisible = true;
		},

		// 立即付款
		toOrderPay(orderListitem) {
			const loading = this.$loading({
				lock: true,
				text: '付款中，请稍后',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			OrderPay(orderListitem.id)
				.then((res) => {
					this.$router.push({
						name: 'orderPay',
						query: {
							id: orderListitem.id
						}
					});
				})
				.finally(() => {
					loading.close();
				});
		},
		// 取消订单
		openOrderCancel(orderListitem) {
			// this.showOrderCancel = true
			let orderList = [orderListitem];
			this.$refs.OrderCancel.open({
				orderList: orderList,
				confirm: () => {
					this.$refs.PopTextarea.open({
						confirm: (e) => {
							let ids = orderList.map((v) => v.id);
							let data = {
								ids: ids,
								closeRemark: e.content
							};
							OrderCancel(data).then((res) => {
								this.toast({
									message: '取消成功',
									type: 'success'
								});
								this.getOrderDetail();
							});
						}
					});
				}
			});
		},
		toOrderCreate(data) {
			if (data) {
				this.$store.commit('changeAgainOrder', data);
				this.$router.push({
					name: 'orderCreate',
					query: {
						type: 'againOrder'
					}
				});
			} else {
				this.$router.push({
					name: 'orderCreate'
				});
			}
		},
		// 客服
		openPopCustomerService(orderListitem) {
			this.$refs.PopCustomerService.open(orderListitem.customerServiceUser);
		},
		openPopAfterSalesApply(orderListitem) {
			this.$refs.PopAfterSalesApply.open(orderListitem);
		},
		// 加急
		openPopOrderUrgent(orderListitem) {
			this.$refs.PopOrderUrgent.open(orderListitem);
		},
		// 催单
		openPopOrderReminder(orderListitem) {
			this.$refs.PopOrderReminder.open({
				id: orderListitem.id
			});
		},
		//
		openPopYuanchengfei(omsOperateRecordListitem) {
			this.$refs.PopYuanchengfei.open(omsOperateRecordListitem);
		},
		toAfterSaleDetail(omsOperateRecordListitem) {
			this.$router.push({
				name: 'afterSaleDetail',
				query: {
					id: omsOperateRecordListitem.relevanceId
				}
			});
		},
		//
		xxxx() {}
	}
};
</script>

<style lang="scss" scoped>
/deep/ .pop-body.OrderCancel {
	box-sizing: border-box;
	padding: 50px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.pb-icon {
		width: 42px;
		height: 42px;
		background-image: url('../assets/066.png');
		background-size: 100% 100%;
	}

	.pb-content {
		margin-top: 20px;
		font-size: 16px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
	}

	.pb-fields {
		display: flex;
		align-items: center;
		margin-top: 20px;

		.pbf-item {
			margin: 0 20px;
			font-size: 12px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #999999;
		}
	}
}

.feiyongmingxi {
	.fy-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.fyi-label {
			font-size: 16px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 32px;
		}

		.fyi-value {
			font-size: 16px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			line-height: 32px;
		}
	}
}

.peijianwuliu {
	min-width: 600px;
	// min-height: 396px;
	background: #ffffff;
	border-radius: 8px 8px 8px 8px;
	box-sizing: border-box;
	padding: 0 20px;

	.pop-header {
		height: 56px;
		display: flex;
		align-items: center;
		position: relative;
		border-bottom: 1px solid #ededed;

		.ph-title {
			font-size: 16px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 16px;
		}

		.ph-close {
			width: 16px;
			height: 16px;
			background-image: url('../assets/058.png');
			background-size: 100% 100%;
			position: absolute;
			right: 0;
		}
	}

	.pop-body {
		box-sizing: border-box;
		padding: 0 0 20px;
		display: flex;
		flex-direction: column;

		.pb-field {
			display: flex;
			align-items: center;
			margin-top: 20px;
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: bold;
			color: #999999;
			line-height: 14px;

			.pbf-label {
				font-weight: 400;
			}

			.pbf-value {
				color: #000000;
			}

			.pbf-copy {
				width: 20px;
				height: 20px;
				background-image: url('../assets/068.png');
				background-size: 100% 100%;
				margin-left: auto;
			}
		}

		.pb-progress {
			display: flex;
			flex-direction: column;

			.pbp-item {
				display: flex;
				padding-top: 20px;
				position: relative;

				&:last-child {
					.pbpi-icons {
						.ii-line {
							display: none;
						}
					}
				}

				.pbpi-date {
					width: 45px;
					font-size: 14px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 400;
					color: #999999;

					.id-date {
					}

					.id-time {
					}
				}

				.pbpi-icons {
					width: 72px;
					display: flex;
					justify-content: center;
					align-items: center;

					// position: relative;
					.ii-dot {
						width: 12px;
						height: 12px;
						background: #d9d9d9;
						border-radius: 50px;
						opacity: 1;
					}

					.ii-line {
						position: absolute;
						width: 0px;
						height: 100%;
						opacity: 1;
						border: 1px solid #d9d9d9;
						transform: translate(0, 50%);
					}
				}

				.pbpi-content {
					.ic-text {
						font-size: 14px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						color: #999999;
						padding-top: 8px;
					}
				}
			}
		}
	}

	.pop-footer {
		border-top: 1px solid #ededed;
		height: 72px;
		display: flex;
		justify-content: center;
		align-items: center;

		.pf-cancel {
			width: 88px;
			height: 32px;
			border-radius: 4px 4px 4px 4px;
			opacity: 1;
			border: 1px solid #3571da;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #3571da;
			line-height: 14px;
			box-sizing: border-box;
			margin: 0 10px;
		}

		.pf-confirm {
			width: 88px;
			height: 32px;
			background: #3571da;
			border-radius: 4px 4px 4px 4px;
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			line-height: 32px;
			text-align: center;
			margin: 0 10px;
		}
	}
}

.yuanchengfeixinxi {
	min-width: 600px;
	// min-height: 396px;
	background: #ffffff;
	border-radius: 8px 8px 8px 8px;
	box-sizing: border-box;
	padding: 0 20px;

	.pop-header {
		height: 56px;
		display: flex;
		align-items: center;
		position: relative;
		border-bottom: 1px solid #ededed;

		.ph-title {
			font-size: 16px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 16px;
		}

		.ph-close {
			width: 16px;
			height: 16px;
			background-image: url('../assets/058.png');
			background-size: 100% 100%;
			position: absolute;
			right: 0;
		}
	}

	.pop-body {
		box-sizing: border-box;
		padding: 0 0 36px;
		display: flex;
		flex-direction: column;

		.pb-text-1 {
			margin: 20px 0;
			font-size: 16px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}

		.li-album {
			box-sizing: border-box;
			display: flex;
			flex-wrap: wrap;

			.lia-item {
				width: 120px;
				height: 120px;
				margin-right: 20px;
				position: relative;
				border-radius: 4px;
				overflow: hidden;

				&:nth-child(4n) {
					margin-right: 0;
				}

				.liai-pic {
					width: 120px;
					height: 120px;
				}
			}
		}

		.pb-text-2 {
			margin-top: 20px;
			font-size: 16px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;

			.money {
				margin-left: 20px;
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #fc474c;
			}
		}
	}

	.pop-footer {
		border-top: 1px solid #ededed;
		height: 72px;
		display: flex;
		justify-content: center;
		align-items: center;

		.pf-cancel {
			width: 88px;
			height: 32px;
			border-radius: 4px 4px 4px 4px;
			opacity: 1;
			border: 1px solid #3571da;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #3571da;
			line-height: 14px;
			box-sizing: border-box;
			margin: 0 10px;
		}

		.pf-confirm {
			width: 88px;
			height: 32px;
			background: #3571da;
			border-radius: 4px 4px 4px 4px;
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			line-height: 32px;
			text-align: center;
			margin: 0 10px;
		}
	}
}

.orderDetail {
	.card {
		.c-header {
			display: flex;
			align-items: center;
			height: 62px;
			margin: 0 20px;
			border-bottom: 1px solid #ededed;

			.ch-left {
				display: flex;
				align-items: center;

				.chl-icon {
					width: 16px;
					height: 16px;
					background-image: url('../assets/048.png');
					background-size: 100% 100%;
				}

				.chl-text {
					margin: 0 5px;
					font-size: 12px;
					font-family: Inter, Inter;
					font-weight: 400;
					color: #8d9094;
				}

				.chl-line {
					font-size: 12px;
					font-family: Inter, Inter;
					font-weight: 400;
					color: #8d9094;
				}
			}

			.ch-right {
				margin-left: auto;
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571da;
			}
		}

		.c-body {
			width: 970px;
			margin: 0 auto;
			padding-bottom: 20px;

			.process {
				display: flex;
				padding: 30px 0;

				.progress-item {
					display: flex;
					align-items: center;

					&.active {
						.pi-num {
							background-color: #3571da;
							border-color: #3571da;
							color: #fff;
						}

						.pin-text {
							color: #18191a;
						}
					}

					&.success {
						.pi-num {
							display: none;
						}

						.pi-icon-box {
							display: flex;
						}

						.pin-text {
							color: #18191a;
						}

						.pin-line {
							background: #3571da;
						}
					}

					.pi-num {
						width: 40px;
						height: 40px;
						box-sizing: border-box;
						border: 2px solid #b4b9bf;
						font-size: 20px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #8d9094;
						line-height: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
					}

					.pi-icon-box {
						display: none;
						width: 40px;
						height: 40px;
						box-sizing: border-box;
						border: 2px solid #3571da;
						justify-content: center;
						align-items: center;
						border-radius: 50%;

						.pi-icon {
							width: 20px;
							height: 20px;
							background-image: url('../assets/007.png');
							background-size: 100% 100%;
						}
					}

					.pin-text {
						margin-left: 6px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #8d9094;
						line-height: 24px;
						white-space: nowrap;
					}

					.pin-line {
						margin: 0 10px 0 6px;
						width: 56px;
						height: 4px;
						background: #ced4db;
					}
				}
			}

			.log {
				box-sizing: border-box;
				padding: 20px;
				display: flex;
				background-color: #f6f8fc;

				.log-content {
					flex: 1;

					.tip {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #fc474c;
						margin-bottom: 10px;
					}

					.log-list {
						.ll-item {
							margin-bottom: 10px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 24px;
							display: flex;
							&.show {
								.lli-content {
									.blue {
										display: initial;
									}
									.red {
										display: initial;
									}
								}
							}

							.lli-time {
								margin-right: 20px;
							}

							.lli-content {
								flex: 1;
								.blue {
									display: none;
									color: #3571da;
									margin-left: 10px;
									cursor: pointer;
								}
								.red {
									display: none;
									color: #fc474c;
									margin-left: 10px;
								}
							}
						}
					}
				}

				.options {
					.btn {
						width: 88px;
						margin-bottom: 15px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						text-align: center;

						&:first-child {
							background: #3571da;
							border-radius: 4px 4px 4px 4px;
							line-height: 32px;
							color: #ffffff;
							cursor: pointer;
							user-select: none;
							&:hover {
								filter: brightness(120%);
								color: #ffffff;
							}
							&:active {
								filter: brightness(80%);
							}
						}
						&:hover {
							cursor: pointer;
							user-select: none;
							color: #3571da;
						}
					}
				}
			}

			.cb-title {
				display: flex;
				align-items: center;
				padding: 30px 0 20px;
				border-bottom: 1px solid #ececec;

				&.shangping {
					border: none;
				}

				.cbt-text {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					line-height: 16px;
				}

				.cbt-edit {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571da;
					margin-left: auto;
					line-height: 16px;
				}
			}

			.kehu-info {
				.ki-field {
					display: flex;
					margin-top: 20px;

					.kif-label {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #999999;
					}

					.kif-value {
						flex: 1;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;

						.blue {
							color: #3571da;
							margin-left: 10px;
							cursor: pointer;
						}
					}
				}
			}

			.list-header {
				display: flex;
				width: 970px;
				margin: 0 auto;

				.lh-item {
					width: 20%;
					background-color: #f7f7f7;
					box-sizing: border-box;
					border: 1px solid #dfdfdf;
					border-left: none;
					height: 48px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #999999;
					border-right-color: transparent;

					&:nth-child(1) {
						width: 40%;
						border-left: 1px solid #dfdfdf;
						border-radius: 8px 0 0 0;
					}

					&:nth-child(4) {
						border-radius: 0 8px 0 0;
						border-right-color: #dfdfdf;
					}
				}
			}

			.list-item {
				display: flex;
				width: 970px;
				margin: 0 auto 0;

				&:last-of-type {
					.li-column {
						&:nth-child(1) {
							border-radius: 0 0 0 8px;
						}

						&:nth-child(4) {
							border-radius: 0 0 8px 0;
						}
					}
				}

				.li-column {
					width: 20%;
					box-sizing: border-box;
					border: 1px solid #dfdfdf;
					border-top: none;
					border-left: none;
					display: flex;

					&:nth-child(1) {
						width: 40%;
						border-left: 1px solid #dfdfdf;
						// border-radius: 8px 0 0 8px;
					}

					// &:nth-child(5) {
					// 	border-radius: 0 8px 8px 0;
					// }
					.column-1 {
						flex: 1;
						display: flex;
						box-sizing: border-box;
						padding: 20px;

						.c1-pic {
							flex-shrink: 0;
							width: 100px;
							height: 100px;
							margin-right: 20px;
						}

						.c1-content {
							display: flex;
							flex-direction: column;
							align-items: flex-start;

							// min-width: 170px;
							.c1c-name {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
							}

							.c1c-field {
								margin-top: 10px;
								display: flex;

								.c1cf-label {
									font-size: 14px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #999999;
								}

								.c1cf-value {
									flex: 1;
									font-size: 14px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
								}
							}
						}
					}

					.column-2 {
						flex: 1;
						display: flex;
						justify-content: center;
						padding: 20px;

						.c2-num {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
					}

					.column-3 {
						flex: 1;
						display: flex;
						justify-content: center;
						box-sizing: border-box;
						padding: 20px;

						.c3-price {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
					}

					.column-4 {
						flex: 1;
						display: flex;
						justify-content: center;
						box-sizing: border-box;
						padding: 20px;

						.c4-total {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
					}
				}
			}

			.list-footer {
				padding-top: 30px;
				display: flex;
				justify-content: flex-end;

				.lf-total {
					display: flex;
					align-items: baseline;

					.lft-label {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						margin-right: 20px;
					}

					.lft-money {
						font-size: 18px;
						font-family: Microsoft YaHei-Bold;
						font-weight: bold;
						color: #fc474c;
					}

					.lft-arrow {
						width: 16px;
						height: 16px;
						margin-left: 10px;
						background-image: url('../assets/056.png');
						background-size: 100% 100%;
					}
				}
			}
		}
	}
}
</style>
