<template>
	<div class="tool" id="tool">
		<div class="item">
			<div class="content" @click="openKeFu()">
				<img src="@/assets/090.png" alt="" />
				<div class="label">在线客服</div>
			</div>
		</div>
		<div class="item">
			<el-popover placement="left" trigger="hover">
				{{ service_hotline }}
				<div class="content" slot="reference">
					<img src="@/assets/091.png" alt="" />
					<div class="label">服务热线</div>
				</div>
			</el-popover>
		</div>
		<div class="item">
			<el-popover placement="left" trigger="hover">
				{{ complaints_hotline }}
				<div class="content" slot="reference">
					<img src="@/assets/092.png" alt="" />
					<div class="label">投诉热线</div>
				</div>
			</el-popover>
		</div>
		<div class="item">
			<div class="content" @click="hasPermi('app:order:add', { toast: true }) && toOrderCreate()">
				<img src="@/assets/093.png" alt="" />
				<div class="label">一键下单</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Config } from '@/common/api.js';
import { XKFOpen } from '@/common/xiaokefu.js';
export default {
	name: 'Tool',
	props: {
		msg: String
	},
	data() {
		return {
			service_hotline: '',
			complaints_hotline: ''
		};
	},
	created() {
		Config('service_hotline').then((res) => {
			this.service_hotline = res.data.configValue;
		});
		Config('complaints_hotline').then((res) => {
			this.complaints_hotline = res.data.configValue;
		});
	},
	methods: {
		openKeFu() {
			XKFOpen();
		},
		toOrderCreate() {
			this.$router.push({
				name: 'orderCreate'
			});
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.tool {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	display: flex;
	flex-direction: column;
	width: 68px;
	// height: 311px;
	background: #ffffff;
	border-radius: 0px 0px 0px 0px;
	// padding: 5px 0;
	.item {
		.content {
			height: 78px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			img {
				width: 28px;
				height: 28px;
			}
			.label {
				margin-top: 10px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 12px;
				color: #333333;
				line-height: 12px;
			}
		}
	}
}
</style>
