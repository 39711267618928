<template>
	<div class="PopOrderUrgent">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="xiugaidenglumima">
				<div class="pop-header">
					<div class="ph-title">订单加急</div>
					<div class="ph-close pointer" @click="close"></div>
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="pop-body">
					<!-- <div class="cb-row">
						<div class="cbr-label">项目名称：</div>
						<div class="cbr-content">
							加急费
							<el-form-item prop="urgentName" class="form-item">
								<el-input v-model="ruleForm.urgentName" class="input type-1" placeholder="请输入"
									clearable></el-input>
							</el-form-item>
						</div>
					</div> -->
					<div class="cb-row">
						<div class="cbr-label">时间段：</div>
						<div class="cbr-content">
							<el-radio v-model="ruleForm.urgentType" :label="1">12小时</el-radio>
							<el-radio v-model="ruleForm.urgentType" :label="2">24小时</el-radio>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">费用金额：</div>
						<div class="cbr-content">
							<el-form-item prop="urgentPrice" class="form-item">
								<el-input v-model="ruleForm.urgentPrice" class="input type-1" placeholder="请输入"
									clearable
									@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'urgentPrice',})"></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close">取消</div>
					<div class="pf-confirm btn-fill" @click="submit">确定</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		OrderUrgent
	} from '../common/api.js'
	var that = {}
	var ruleForm = {
		orderId: '', // 
		urgentName: '', // 加急项目
		urgentPrice: '', // 加急项目金额
		urgentType: 1, // 
	}
	export default {
		name: 'PopOrderUrgent', //订单加急
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: {
					...ruleForm
				},
				rules: {
					urgentName: [{
						required: true,
						message: '请输入项目名称',
						trigger: 'blur'
					}],
					urgentPrice: [{
						required: true,
						message: '请输入费用金额',
						trigger: 'blur'
					}],
				},

			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					if (!val) {
						this.$refs.ruleForm.resetFields()
						this.ruleForm = {
							...ruleForm
						}
					}
					this.$emit('update:show', val)
				}
			},

		},
		created() {
			that = this
		},
		methods: {
			open(data) {
				if (!data) {
					this.ruleForm = {
						...ruleForm
					}
				} else {
					this.ruleForm.orderId = data.id //
					this.ruleForm.urgentName = data.urgentName || '' // 加急项目
					this.ruleForm.urgentPrice = data.urgentPrice || '' // 加急项目金额
					this.ruleForm.urgentType = data.urgentType || 1 // 加急项目金额
				}
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = {
							orderId: this.ruleForm.orderId, //
							urgentName: '加急费', // 加急项目
							// urgentName: this.ruleForm.urgentName, // 加急项目
							urgentPrice: this.ruleForm.urgentPrice, // 加急项目金额
							urgentType: this.ruleForm.urgentType, // 
						}
						console.log('data: ', data);
						OrderUrgent(data).then(res => {
							this.toast({
								message: '修改成功',
								type: 'success'
							})
							this.close()
							this.$emit('success')
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

		}
	}
</script>

<style lang="scss" scoped>
	.PopOrderUrgent {
		.xiugaidenglumima {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;

			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;

				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}

				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}

			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;

				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;

					.cbr-label {
						width: 80px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;

						.star {
							color: #FF0000;
						}
					}

					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;

						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						.yanzhengma {
							position: relative;

							.yanzhengma-btn {
								position: absolute;
								right: 0;
								top: 0;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #3571DA;
								line-height: 32px;
								width: 80px;
								text-align: center;
							}
						}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 320px;
							}
						}
					}
				}

			}

			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;

				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}

				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>