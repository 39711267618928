import config from "../common/config.js"
import store from '../store/index.js'
import {
	mapState,
	mapGetters
} from 'vuex'
import {
	starFilter
} from '../common/utils.js'
import {
	imgUrl,
	downloadFile
} from '../common/h5.js'
import {
	filesDownloadZip
} from '../common/request.js'
import debounce from 'lodash/debounce'

export default {
	data() {
		return {
			webImg: config.imgRoot, //网络图片前缀
			_lastTime: null, //上次点击时间
			isOffLine: false, //是否断网了
			videoFileType: ['mp4', 'avi', 'wmv'],
			pageSizesOptions: [10, 20, 30, 50, 100],
			time: {
				fullYear: "",
				month: "",
				date: "",
				week: "",
				hours: "",
				minutes: "",
				seconds: "",
				weekName: "",
			},
			longText: '最近，谷歌发布了新的一篇论文介绍了一项新技术，使大语言模型能够处理无限长度的上下文。新论文声称可赋予大型语言模型（LLM）处理无限长度文本的能力。该论文介绍了无限注意力（Infini-attention），这是一种以扩展“上下文窗口”的方式配置语言模型的技术，同时保持内存和计算需求不变。',
			verifyAccountTemp: '',

		};
	},
	computed: {
		...mapState(['userInfo', 'pc']),
		...mapGetters(['isLogin', 'isSubAccount'])
	},
	mounted() {
		this.getTime()
	},
	updated() {
		// 当预览图片时，阻止滚动条滚动
		this.handleIsElImageViewerWrapperShowDebounce()
	},
	filters: {
		// 服务项目
		orderServiceTypeFilter(v) {
			// 服务项目 1=安装2=维修3=清洗4=调试
			if (v) {
				if (v == 1) {
					return '安装'
				} else if (v == 2) {
					return '维修'
				} else if (v == 3) {
					return '清洗'
				} else if (v == 4) {
					return '调试'
				} else if (v == 5) {
					return '回收'
				}
			} else {
				return '无'
			}

		},
		// 订单节点
		orderNodeFilter(v) {
			// console.log('orderNodeFilter: ', v);
			// 订单节点 支付成功 210 指派师傅 220 指派师傅确认 230 指派师傅拒绝 240 师傅接单 310 预约上门 410 修改时间 420 打卡成功 510 安装材料提交 520 待配件 530 配件已发货 540 配件已到达 550 完工申请 560 保外付款成功 572 评价完成 670 订单完成 710 返件待申核 630 返件申请驳回 634 促好评待审核 650 返件申核通过 640 促评申核通过 660 促评申核驳回 664 已关闭 810 待审核 910 同意售后920(售后指派师傅确认 920220 售后指派师傅确认 920230 售后指派师傅拒绝 920240 售后预约上门 920410 售后修改时间 920420 售后打卡成功 920510 售后安装材料提交 920520 售后待配件 920530 售后配件已发货 920540 售后配件已到达 920550 售后完工申请 920560 售后保外付款成功 920572 售后返件待申核 920630 售后返件申核通过 920640) 售后申请驳回 930 售后完成 940
			if (v) {
				let result = store.state.orderNodeList.find(e => e.value == v)
				if (result) {
					return result.label
				} else {
					return ''
				}
			} else {
				return '无'
			}

		},
		// 订单状态
		orderStatusFilter(v, keyName = 'label') {
			// console.log('orderStatusFilter: ', v);
			// 订单状态 待付款100,待接单200,待预约300,待施工400,施工中500,待验收600,已完成700,已关闭800,售后900
			if (v) {
				let result = store.state.orderStatusList.find(e => e.value == v)
				if (result) {
					return result[keyName]
				} else {
					return ''
				}
			} else {
				if (keyName == 'label') {
					return '无'
				} else if (keyName == 'color') {
					return ''
				}
			}
		},
		// 转虚拟号
		phoneExtensionFilter(v) {
			return v ? `转${v}` : ''
		},
		// 格式化为0
		zeroFilter(v) {
			if (v === '' || v === null || v === 'null' || v === undefined || v === 'undefined') {
				return 0
			} else {
				return v
			}
		},
		// 显示隐藏
		visibilityFilter(v) {
			if (v) {
				return {
					visibility: 'visible'
				}
			} else {
				return {
					visibility: 'hidden'
				}
			}
		},
		// 背景颜色
		backgroundColorFilter(v,key) {
			let backgroundColor = ''
			if (
				key == 'orderStatus' && v == 100
			) {
				backgroundColor = '#409eff'
			}
			else if (
				key == 'orderStatus' && v == 200
			) {
				backgroundColor = '#EE82EE'
			}
			else if (
				key == 'orderStatus' && v == 210
			) {
				backgroundColor = '#de770f'
			}
			else if (
				key == 'orderStatus' && v == 300
			) {
				backgroundColor = '#944806'
			}
			else if (
				key == 'orderStatus' && v == 310
			) {
				backgroundColor = '#06b649'
			}
			else if (
				key == 'orderStatus' && v == 400
			) {
				backgroundColor = '#f0a800'
			}
			else if (
				key == 'orderStatus' && v == 500
			) {
				backgroundColor = '#87CEEB'
			}
			else if (
				key == 'orderStatus' && v == 510
			) {
				backgroundColor = '#3665e3'
			}
			else if (
				key == 'orderStatus' && v == 600
			) {
				backgroundColor = '#FF7F50'
			}
			else if (
				key == 'orderStatus' && v == 650
			) {
				backgroundColor = '#7d24ed'
			}
			else if (
				key == 'orderStatus' && v == 700
			) {
				backgroundColor = '#46c26f'
			}
			else if (
				key == 'orderStatus' && v == 800
			) {
				backgroundColor = '#485970'
			}
			else if (
				key == 'orderStatus' && v == 900
			) {
				backgroundColor = '#FF0000'
			}
			else {
				backgroundColor = '#FF0000'
			}
			return `backgroundColor: ${backgroundColor};`
		},
		// 星星屏蔽
		starFilter(data = {}) {
			return starFilter(...arguments)
		},
	},
	methods: {
		handleIsElImageViewerWrapperShowDebounce: debounce(function() {
			let elImageViewerWrapper = document.getElementsByClassName('el-image-viewer__wrapper')
			if (elImageViewerWrapper && elImageViewerWrapper.length) {
				store.commit('changeIsElImageViewerWrapperShow', true)
			} else {
				store.commit('changeIsElImageViewerWrapperShow', false)
			}
		}, 500),
		navigateBack(delta = -1) {
			this.$router.go(delta)
		},
		//检测有无token
		checkUser() {
			console.log('localStorage.getItem("token"): ', localStorage.getItem("token"));
			if (!localStorage.getItem("token")) {
				return false
			}
			return true;
		},
		handleVisiable(e) {
			let isExist = e.target.visibilityState
			console.log(isExist)
			if (isExist === 'visible') {
				this.mathRdm()
				console.log(new Date().toLocaleString(), `您已进入页面！`)
			} else {
				clearInterval(this.timer)
				console.log(new Date().toLocaleString(), `您已离开页面！`)
			}
			switch (e.target.visibilityState) {
				case 'prerender':
					console.log('网页预渲染，内容不可见')
					break;
				case 'hidden':
					console.log('内容不可见，处理后台、最小化、锁屏状态')
					break;
				case 'visible':
					console.log('处于正常打开')
					break;
			}
		},
		mathRdm() {
			let num = 5000
			this.timer = setInterval(() => {

			}, num)
		},
		/**
		 * 数字字段值转换 规避toFixed 精度丢失问题
		 * @param {输入的数字 string、int 类型均可} n
		 * @param {保留位数 可输入 0} d
		 * @returns
		 */
		toFixedNum(n, d) {
			// 判断是小数
			var isFloat = parseInt(n) < parseFloat(n);

			if (typeof(d) == "undefined") {
				// 获取浮点型数值的小数位数
				d = (n != Math.floor(n)) ? (n.toString()).split('.')[1].length : 0;
			}

			if (isFloat) {
				n = Number(n);
				d = Number(d);
				n = n * Math.pow(10, d);
				n = Math.round(n);
				n = Number(n);
				n = n / Math.pow(10, d);
				n = n.toFixed(d);
				n = Number(n);
			} else {
				n = Number(n);
			}

			return n;
		},
		throttle(fn, gapTime) {
			if (gapTime == null || gapTime == undefined) {
				gapTime = 500 // 默认500毫秒
			}
			let _nowTime = +new Date()
			if (_nowTime - this._lastTime > gapTime || !this._lastTime) {
				fn()
				// this.$message.success('刷新成功')
				this._lastTime = _nowTime
			}
		},
		//检测有无token
		checkUser() {
			console.log('localStorage.getItem("token"): ', localStorage.getItem("token"));
			if (!localStorage.getItem("token")) {
				return false
			}
			return true;
		},
		// 返回
		goBack(delta = -1) {
			window.history.length > 1 ? this.$router.go(delta) : this.$router.push('/')
		},
		// 消息
		toast(Options) {
			if (typeof Options === "string") {
				this.$message({
					message: Options,
					type: 'warning',
				});
			} else {
				this.$message({
					message: '',
					type: '',
					customClass: '',
					...Options
				});
			}

		},
		MHome() {
			this.$router.push('/home')
		},
		openHome() {
			this.$router.push({
				name: 'index'
			})
		},
		toLink(path) {
			this.$router.push(path)
		},
		// 图片路径
		imgUrl(url, showDefault, defaultUrl) {
			return imgUrl(url, showDefault, defaultUrl)
		},
		// 文件下载
		downloadFile(data) {
			return downloadFile(...arguments)
		},
		// 视频封面路径
		videoUrl(url, showDefault, defaultUrl) {
			return imgUrl(url, showDefault, defaultUrl) +
				'?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast'
		},
		// 权限判断
		hasPermi(permissions, options = {}) {
			let {
				toast = false
			} = options
			if (store.state.userPermissions.some(v => v == "*:*:*")) {
				return true
			} else {
				if (!permissions) {
					return true
				} else {
					if (!Array.isArray(permissions)) {
						permissions = permissions.split(',')
					}
					// console.log('permissions: ', permissions);
					for (var i = 0; i < permissions.length; i++) {
						if (!store.state.userPermissions.some(v => v == permissions[i])) {
							if (toast) {
								this.toast({
									message: '没有权限',
									type: 'info'
								})
							}
							return false
						}
					}
					return true
				}
			}
		},
		getTime() {
			setInterval(() => {
				const newtime = new Date();
				const weeks = ["星期天","星期一","星期二","星期三","星期四","星期五","星期六"]
				this.time.hours = this.getIncrease(newtime.getHours(), 2);
				this.time.minutes = this.getIncrease(newtime.getMinutes(), 2);
				this.time.seconds = this.getIncrease(newtime.getSeconds(), 2);
				this.time.fullYear = this.getIncrease(newtime.getFullYear(), 4);
				this.time.month = this.getIncrease(newtime.getMonth() + 1, 2);
				this.time.date = this.getIncrease(newtime.getDate(), 2);
				this.time.week = newtime.getDay();
				this.time.weekName = weeks[this.time.week];
			}, 1000)
		},
		// 补0
		getIncrease(num, digit) {
			var increase = "";
			for (var i = 0; i < digit; i++) {
				increase += "0";
			}
			return (increase + num).slice(-digit);
		},
		// 验证值是否正整数
		verifyPositiveInteger(arg,options) {
			let {
				key1 = '',
				key2 = '',
				key3 = '',
				showToast = true,
				message = '请输入正整数',
				type = 'warning',
			} = options
			let verifyResult = false
			if (arg == 'this') {
				if (key3) {
					let value = this[key1][key2][key3]
					this[key1][key2][key3] = this[key1][key2][key3].replace(/\D/g,'')
					verifyResult = this[key1][key2][key3] == value
				} else if(key2) {
					let value = this[key1][key2]
					this[key1][key2] = this[key1][key2].replace(/\D/g,'')
					verifyResult = this[key1][key2] == value
				} else {
					let value = this[key1]
					this[key1] = this[key1].replace(/\D/g,'')
					verifyResult = this[key1] == value
				}
			} else {
				if (key3) {
					let value = arg[key1][key2][key3]
					arg[key1][key2][key3] = arg[key1][key2][key3].replace(/\D/g,'')
					verifyResult = arg[key1][key2][key3] == value
				} else if(key2) {
					let value = arg[key1][key2]
					arg[key1][key2] = arg[key1][key2].replace(/\D/g,'')
					verifyResult = arg[key1][key2] == value
				} else {
					let value = arg[key1]
					arg[key1] = arg[key1].replace(/\D/g,'')
					verifyResult = arg[key1] == value
				}
			}
			
			if (showToast && !verifyResult) {
				this.toast({
					message: message,
					type: type
				})
			}
			
		},
		// 验证-输入只能由数字、字母、汉字组成
		verifyAccount(options) {
			let {
				arg = '',
				key1 = '',
				key2 = '',
				key3 = '',
				showToast = true,
				message = '含有非法字符，内容只能包含数字、字母、汉字',
				type = 'warning',
				val = '',
				oldVal = '',
			} = options
			let verifyResult = false
			const regex = /^[\da-zA-Z\u4e00-\u9fa5]+$/
			console.log('val: ',val);
			if (val) {
				verifyResult = regex.test(val)
			} else {
				verifyResult = true
			}
			if (options.hasOwnProperty('oldVal')) {
				if (arg == 'this') {
					if (key3) {
						this[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						this[key1][key2] = verifyResult ? val : oldVal
					} else {
						this[key1] = verifyResult ? val : oldVal
					}
				} else {
					if (key3) {
						arg[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						arg[key1][key2] = verifyResult ? val : oldVal
					} else {
						arg[key1] = verifyResult ? val : oldVal
					}
				}
			}
			
			if (showToast && !verifyResult) {
				this.toast({
					message: message,
					type: type
				})
			}
			
			return verifyResult
			
		},
		// 验证-手机号
		verifyPhone(options) {
			let {
				arg = '',
				key1 = '',
				key2 = '',
				key3 = '',
				showToast = true,
				message = '手机号格式不正确',
				type = 'warning',
				val = '',
				oldVal = '',
			} = options
			let verifyResult = false
			const regex = /^1[3-9]\d{9}$/
			console.log('val: ',val);
			if (val) {
				verifyResult = regex.test(val)
			} else {
				verifyResult = true
			}
			
			if (options.hasOwnProperty('oldVal')) {
				if (arg == 'this') {
					if (key3) {
						this[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						this[key1][key2] = verifyResult ? val : oldVal
					} else {
						this[key1] = verifyResult ? val : oldVal
					}
				} else {
					if (key3) {
						arg[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						arg[key1][key2] = verifyResult ? val : oldVal
					} else {
						arg[key1] = verifyResult ? val : oldVal
					}
				}
			}
			
			if (showToast && !verifyResult) {
				this.toast({
					message: message,
					type: type
				})
			}
			
			return verifyResult
			
		},
		// 验证-字符长度
		verifyLength(options) {
			let {
				arg = '',
				key1 = '',
				key2 = '',
				key3 = '',
				showToast = true,
				message =  `长度不能小于，长度不能大于`,
				type = 'warning',
				val = '',
				oldVal = '',
				min = '',
				max = ''
			} = options
			
			if (!message) {
				if (min && max) {
					message = `长度不能小于${min}，长度不能大于${max}`
				} else if(min) {
					message = `长度不能小于${min}`
				} else if(max) {
					message = `长度不能大于${max}`
				}
			}
			
			let verifyResult = false
			// const regex = /^1[3-9]\d{9}$/
			console.log('val: ',val);
			if (val) {
				if (min && val.length < min) {
					verifyResult = false
				} else if (max && val.length > max) {
					verifyResult = false
				} else {
					verifyResult = true
				}
			} else {
				verifyResult = true
			}
			if (options.hasOwnProperty('oldVal')) {
				if (arg == 'this') {
					if (key3) {
						this[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						this[key1][key2] = verifyResult ? val : oldVal
					} else {
						this[key1] = verifyResult ? val : oldVal
					}
				} else {
					if (key3) {
						arg[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						arg[key1][key2] = verifyResult ? val : oldVal
					} else {
						arg[key1] = verifyResult ? val : oldVal
					}
				}
			}
			
			if (showToast && !verifyResult) {
				this.toast({
					message: message,
					type: type
				})
			}
			
			return verifyResult
			
		},
		// 复制
		copyData(text) {
			try {
				const t = document.createElement('textarea')
				t.nodeValue = text
				t.value = text
				document.body.appendChild(t)
				t.select()
				document.execCommand('copy');
				document.body.removeChild(t)
		
				this.toast({
					message: '复制成功',
					type: 'success'
				})
		
				return true
			} catch (e) {
				console.log(e)
				return false
			}
		},
		//控制富文本图片大小
		formatRichText(html) {
			let newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
				match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
				match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
				match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
				return match;
			});
			newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
				match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi,
					'max-width:100%;');
				return match;
			});
			newContent = newContent.replace(/<br[^>]*\/>/gi, '');
			newContent = newContent.replace(/\<img/gi,
				'<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
			return newContent;
		},
	},
}