<template>
	<div class="PopUpdatePhone">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="xiugaidenglumima">
				<div v-show="step == 1">
					<div class="pop-header">
						<div class="ph-title">验证旧手机号</div>
						<div class="ph-close pointer" @click="close"></div>
					</div>
					<div class="pop-body">
						<div class="cb-row">
							<div class="cbr-label">手机号：</div>
							<div class="cbr-content">
								<div class="cbrc-value">{{userInfo.phone | starFilter(4,4,'*')}}</div>
							</div>
						</div>
						<div class="cb-row code">
							<div class="cbr-label">图形验证码：</div>
							<div class="cbr-content">
								<el-form-item prop="code" class="form-item yanzhengma" key="oldCountdown">
									<el-input v-model="ruleForm.code" class="input type-1" placeholder="请输入" clearable></el-input>
									<div class="verify-image" @click="getCaptchaImage()">
										<img :src="captchaImage" alt="" />
									</div>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">短信验证码：</div>
							<div class="cbr-content">
								<el-form-item prop="captcha" class="form-item yanzhengma" key="oldCountdown">
									<el-input v-model="ruleForm.captcha" class="input type-1" placeholder="请输入" clearable></el-input>
									<Countdown  ref="oldCountdown" :seconds="60" class="yanzhengma-btn" @click="getCode"></Countdown>
								</el-form-item>
							</div>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel btn-fill" @click="close">取消</div>
						<div class="pf-confirm btn-fill" @click="next">下一步</div>
					</div>
				</div>
				<div v-show="step == 2">
					<div class="pop-header">
						<div class="ph-title">确认新手机号</div>
						<div class="ph-close pointer" @click="close"></div>
					</div>
					<div class="pop-body">
						<div class="cb-row">
							<div class="cbr-label">新手机号：</div>
							<div class="cbr-content">
								<el-form-item prop="newPhone" class="form-item">
									<el-input v-model="ruleForm.newPhone" class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row code">
							<div class="cbr-label">图形验证码：</div>
							<div class="cbr-content">
								<el-form-item prop="newCode" class="form-item yanzhengma" key="Countdown">
									<el-input v-model="ruleForm.newCode" class="input type-1" placeholder="请输入" clearable></el-input>
									<div class="verify-image" @click="getCaptchaImage()">
										<img :src="captchaImage" alt="" />
									</div>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">短信验证码：</div>
							<div class="cbr-content">
								<el-form-item prop="newCaptcha" class="form-item yanzhengma" key="Countdown">
									<el-input v-model="ruleForm.newCaptcha" class="input type-1" placeholder="请输入" clearable></el-input>
									<Countdown  ref="Countdown" :seconds="60" class="yanzhengma-btn" @click="getCode"></Countdown>
								</el-form-item>
							</div>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel btn-fill" @click="close">取消</div>
						<div class="pf-confirm btn-fill" @click="submit">确定</div>
					</div>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		SendCode,
		SendPhoneSmsCode,
		UpdateChangePhoneCaptcha,
		UpdateChangePhone,
		CaptchaImage
	} from '../common/api.js'
	var that = {}
	var numberChecked = (rule, value, callback) => {
		if (!isNaN(parseFloat(value)) && isFinite(value)) {
			callback();
		} else {
			return callback(new Error('请勿输入数字以外的字符'));
		}
	};
	export default {
		name: 'PopUpdatePhone',//修改手机号
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: {
					captcha: '', // 验证码
					newCaptcha: '', // 新手机号验证码
					newPhone: '', // 新手机号
					code: '',
					newCode: ''
				},
				rules: {
					captcha: [{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 6,
						max: 6,
						message: '短信验证码位数不正确',
						trigger: 'blur'
					}],
					newCaptcha: [{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 6,
						max: 6,
						message: '短信验证码位数不正确',
						trigger: 'blur'
					}],
					newPhone: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 11,
						max: 11,
						message: '手机号位数不正确',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入图形验证码',
						trigger: 'blur'
					},],
					newCode: [{
						required: true,
						message: '请输入图形验证码',
						trigger: 'blur'
					},],
				},
				step: 1,
				captchaImage: '',
				uuid: ''
				
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					if (!val) {
						this.step = 1
						this.$refs.ruleForm.resetFields()
					} else {
						this.getCaptchaImage()
					}
					this.$emit('update:show', val)
				}
			},
			
		},
		created() {
			that = this
		},
		methods: {
			getCaptchaImage() {
				CaptchaImage().then(res => {
					this.captchaImage = "data:image/gif;base64," + res.img
					this.uuid = res.uuid
				})
			},
			close() {
				this.dialogVisible = false
			},
			next() {
				let validateFieldList= []
				this.$refs.ruleForm.validateField(['captcha','code'], (valid) => {
					if (valid) {
						console.log(valid);
					} else {
						console.log(valid);
						validateFieldList.push(valid)
						if (validateFieldList.length == 2 && validateFieldList.every((item) => item === "")) {
						  // 之后的操作
						  let data = {
						  	captcha: this.ruleForm.captcha, // 验证码
						  }
						  UpdateChangePhoneCaptcha(data).then(res => {
						  	this.step = 2
						  })
						}
					}
				});
			},
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = {
							captcha: this.ruleForm.captcha, // 验证码
							newCaptcha: this.ruleForm.newCaptcha, // 新手机号验证码
							newPhone: this.ruleForm.newPhone, // 新手机号
						}
						UpdateChangePhone(data).then(res => {
							this.toast({
								message: '修改成功',
								type: 'success'
							})
							this.close()
							this.$store.dispatch('updateUserInfo')
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getCode() {
				if (this.step == 1) {
					this.$refs.ruleForm.validateField(['code'], (valid) => {
						if (valid) {
							console.log(valid);
					
						} else {
							console.log(valid);
							if (this.$refs.oldCountdown.canGetCode) {
								let data = {
									code: this.ruleForm.code,
									uuid: this.uuid,
								}
								SendPhoneSmsCode(data).then(res => {
									this.$refs.oldCountdown.start()
								}).catch(err => {
									this.getCaptchaImage()
								})
							}
						}
					});
				} else if(this.step == 2) {
					let validateFieldList= []
					this.$refs.ruleForm.validateField(['newPhone', 'newCode'], (valid) => {
						if (valid) {
							console.log(valid);
						} else {
							// console.log(valid);
							validateFieldList.push(valid)
							if (validateFieldList.length == 2 && validateFieldList.every((item) => item === "")) {
							  // 之后的操作
							  if (this.$refs.Countdown.canGetCode) {
							  	let data = {
							  		phone: this.ruleForm.newPhone,
									code: this.ruleForm.newCode,
									uuid: this.uuid,
							  		type: 'changePhone', // 类型register注册login验证码登录forget忘记密码changePhone更换手机号
							  	}
							  	SendCode(data).then(res => {
							  		this.$refs.Countdown.start()
							  	}).catch(err => {
									this.getCaptchaImage()
								})
							  }
							}
						}
					});
					
				}
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep {
		.el-form-item__content {
			line-height: initial;
		}
	}
	.verify-image {
		margin: 0 0 0 10rpx;
		width: 150px;
		height: 58px;
		position: absolute;
		right: 0;
		top: 0;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.PopUpdatePhone {
		.xiugaidenglumima {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;

			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;

				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}

				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}

			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;

				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;
					
					&.code {
						/deep/.input {
							input {
								height: 58px !important;
							}
						}
					}

					.cbr-label {
						width: 100px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;

						.star {
							color: #FF0000;
						}
					}

					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;

						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						.yanzhengma {
							position: relative;

							.yanzhengma-btn {
								position: absolute;
								right: 0;
								top: 0;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #3571DA;
								line-height: 32px;
								width: 80px;
								text-align: center;
							}
						}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 320px;
							}
						}
					}
				}

			}

			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;

				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}

				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>